import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import axios from "axios";

const progress = 100;
const complete = 50;
const total = 200;

const size = {
  width: 250,
  height: 150,
};

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 15,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const LegendItem = styled(Box)(({ color }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 8,
  "&:before": {
    content: '""',
    display: "inline-block",
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: 8,
  },
}));

function CustomLegend({ data }) {
  return (
    <Box display="flex" gap={2} mt={1}>
      {data.map((item) => (
        <LegendItem key={item.label} color={item.color}>
          <Typography variant="body2" color={"black"}>
            {item.label}
          </Typography>
        </LegendItem>
      ))}
    </Box>
  );
}
export default function Pie() {
  const [progressCnt, setProgressCnt] = useState(0);
  const [completedCnt, setCompletedCnt] = useState(0);
  const [delayedCnt, setDelayedCnt] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);

  const data = [
    { value: progressCnt, color: "#abf4aa" },
    { value: completedCnt, color: "#918ff9" },
    { value: totalCnt - (progressCnt + completedCnt), color: "#eeeeee" },
  ];

  const Labeldata = [
    { value: progressCnt, label: "In Progress", color: "#abf4aa" },
    { value: completedCnt, label: "Completed", color: "#918ff9" },
    {
      value: totalCnt - (progressCnt + completedCnt),
      label: "Total",
      color: "#eeeeee",
    },
  ];

  const getEmployeeTaskCounts = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/employee-tasks-cnt?employeeId=${localStorage.getItem("userId")}`
      );
      if (response.status === 200) {
        setCompletedCnt(response.data.completedTasks);
        setDelayedCnt(response.data.delayedTasks);
        setProgressCnt(response.data.inProgressTasks);
        setTotalCnt(response.data.totalTasks);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getEmployeeTaskCounts();
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="30%"
      borderRadius={2}
      boxShadow=" rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
    >
      <Typography variant="h6">Overall Progress</Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        <PieChart
          series={[
            {
              data,
              innerRadius: 60,
              outerRadius: 70,
              label: { display: false },
            },
          ]}
          {...size}
        >
          <PieCenterLabel>
            {`${(completedCnt / totalCnt) * 100}`.slice(0, 3)}% Completed
          </PieCenterLabel>
        </PieChart>
      </Box>

      <Box textAlign="center" display="flex" gap={2} mt={1}>
        <Typography variant="body1" fontSize={14}>
          <span>{totalCnt}</span> <br />
          <span className="text-[#b8b8c6]">Total Tasks</span>
        </Typography>
        <Typography variant="body1" fontSize={14}>
          <span>{progressCnt}</span> <br />
          <span className="text-[#b8b8c6]">In Progress</span>
        </Typography>
        <Typography variant="body1" fontSize={14}>
          <span>{completedCnt}</span> <br />
          <span className="text-[#b8b8c6]">Completed</span>
        </Typography>
      </Box>
      <CustomLegend data={Labeldata} />
    </Box>
  );
}
