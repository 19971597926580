import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dash_bg from "../../assets/teamLead/dash_bg.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const NewTask = () => {
  const [newProject, setNewProject] = useState({});
  const navigate = useNavigate();
  const getMostRecentProjectByTeamLead = async () => {
    try {
      const role =
        localStorage.getItem("role") === "TEAMLEAD" ? "teamlead" : "employee";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${role}-new-project`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setNewProject(response.data.mostRecentProject);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getMostRecentProjectByTeamLead();
  }, []);

  return (
    <div className="flex h-[34vh] justify-start items-end w-full gap-x-2">
      <div className="bg-white rounded-2xl  w-[70%] h-full p-4">
        <h2 className="font-semibold md:text-2xl text-lg text-[#625FFF] mt-2 mb-2">
          Welcome {localStorage.getItem("name")} 👋
        </h2>
        <div className="h-[80%] relative w-full">
          <img src={dash_bg} className="h-[100%] w-full" />
          <div className="absolute top-0 px-4 py-2 w-full">
            <div className="w-[70%]">
              <h1 className="text-white text-[18px] font-semibold mb-2">
                Admin added to you in new project 📁
              </h1>
              <div className="flex w-[100%] text-white">
                <p className="w-[50%]">Project Title - </p>
                <p>{newProject?.projectName}</p>
              </div>
              <div className="flex w-[100%] text-white">
                <p className="w-[50%]">Team Lead - </p>
                <p>{newProject?.teamLead?.name}</p>
              </div>
              <div className="flex w-[100%] text-white">
                <p className="w-[50%]">Team Members - </p>
                {newProject?.employees?.map((employee) => (
                  <p key={employee?.id}>{employee?.name},</p>
                ))}
              </div>
            </div>
            {localStorage.getItem("role") === "TEAMLEAD" && (
              <div className="w-[100%] ">
                <button
                  className=" p-2 -top-[4px] relative bg-[#625FFF] text-white text-sm rounded-md float-right"
                  onClick={() => navigate(`/view-task/${newProject.id}`)}
                >
                  View Details
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-[30%] h-full ">
        <Calendar className="md:text-[12px] custom-calaendar text-[10px]   h-[34vh] p-1  w-full " />
      </div>
    </div>
  );
};

export default NewTask;
