import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { useLocation } from "react-router-dom";
import RequirementModal from "../../modal/teamLead/RequirementModal";
import ProjectInfo from "./viewTask/ProjectInfo";
import ClientInfo from "./viewTask/ClientInfo";
import ProjectTeamMembers from "./viewTask/ProjectTeamMembers";
import TaskList from "./viewTask/TaskList";

const ViewTask = () => {
  const location = useLocation();
  const teamLeadData = location.state;
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");

  const filterTasksByStatus = (status) => {
    if (status === "All") {
      return teamLeadData.tasks;
    } else {
      return teamLeadData.tasks.filter((task) => task.status === status);
    }
  };

  const [projectData, setProjectData] = useState({
    clientInfo: {
      name: "Pankaj Gawali",
      contact: "+91 9665883207",
      mail: "myresearchroom.com",
    },
    projectInfo: {
      startDate: "2024-02-26",
      endDate: "2024-02-12",
      requirements: "Details",
    },
    teamMembers: ["Sohail H.", "Rohit S.", "Aditi S."],
  });

  const handleSave = () => {
    // Implement logic to save the project data
    console.log("Saving project data:", projectData);
  };

  const handleInfoChange = (section, field, value) => {
    setProjectData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleMemberChange = (index, value) => {
    const updatedMembers = [...projectData.teamMembers];
    updatedMembers[index] = value;
    setProjectData((prevData) => ({
      ...prevData,
      teamMembers: updatedMembers,
    }));
  };

  // const handleMemberChange = (leadName, employeeArray) => {
  //     setProjectData(prevData => ({
  //         ...prevData,
  //         teamMembers: {
  //             leadName: leadName,
  //             employees: employeeArray
  //         }
  //     }));
  // };

  const addNewMember = () => {
    setProjectData((prevData) => ({
      ...prevData,
      teamMembers: [...prevData.teamMembers, ""],
    }));
  };

  const [editableSection, setEditableSection] = useState(null);
  const toggleEditableSection = (section) => {
    setEditableSection(editableSection === section ? null : section);
  };

  return (
    <>
      {/* {showRequirement && <RequirementModal setShowRequirement={setShowRequirement} selectedTaskRequirements={selectedRequirements}/>} */}
      <div className="mr-4 bg-white rounded-2xl py-4 px-2 relative top-4 h-[88vh]">
        <div className="flex justify-between items-center h-[10%]">
          <h2 className="text-2xl font-medium text-[#625FFF] ">Project</h2>
          <div className="flex gap-2 ">
            <div>
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                type="search"
                placeholder="search here..."
                className="outline-none rounded-md border p-2"
              />
            </div>
            <select
              className="text-white py-2 w-[200px] px-4 bg-[#625FFF] rounded-md outline-none"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="" disabled selected>Sort By</option>
              <option>All</option>
              <option>In Progress</option>
              <option>Completed</option>
              <option>Delayed</option>
            </select>
          </div>
        </div>

        <div
          className="w-full h-[80%] overflow-y-auto"
          style={{ scrollbarWidth: "none" }}
        >
          <TaskList selectedStatus={selectedStatus} searchTerm={searchTerm} />
          <ProjectInfo />
        </div>
      </div>
    </>
  );
};

export default ViewTask;
