import React, { useEffect, useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";

const Accounts = () => {
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const [newTransaction, setNewTransaction] = useState({
    paymentMode: "",
    amountPaid: "",
    paidOn: "",
  });

  const handleTransactionChange = (e, index, field) => {
    const updatedTransactions = [...transactions];
    updatedTransactions[index][field] = e.target.value;
    setTransactions(updatedTransactions);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isAddingRow, setIsAddingRow] = useState(false);

  const calculatePaymentStatus = (project) => {
    const { projectCost, amountPaid, dueDate } = project;

    // Convert string values to numbers
    const parsedProjectCost = parseFloat(projectCost);
    const parsedAmountPaid = parseFloat(amountPaid);
    const parsedDueDate = new Date(dueDate);

    // Check if due date is past
    const isPastDueDate = parsedDueDate < new Date();

    if (parsedAmountPaid >= parsedProjectCost && !isPastDueDate) {
      return "Paid in full";
    } else if (
      parsedAmountPaid > 0 &&
      parsedAmountPaid < parsedProjectCost &&
      !isPastDueDate
    ) {
      return "Partially Paid";
    } else if (parsedAmountPaid > 0 && isPastDueDate) {
      return "Payment delay";
    } else if (parsedAmountPaid === 0 && isPastDueDate) {
      return "Payment delay";
    } else {
      return "Pending"; // Default status if none of the conditions match
    }
  };

  const getTransactions = async (projectId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/project-transactions/${projectId}`
      );
      if (response.status === 200) {
        setTransactions(response?.data?.transactions);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const getProjects = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/project`,
        {
          department: currentDepartment,
          searchTerm,
        }
      );

      if (response.status === 200) {
        setProjects(response.data.projects);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || "An error occured");
    }
  };

  const getDepartments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/department`
      );
      if (response.status === 200) {
        setDepartments(response?.data?.departments);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const handleAddRow = () => {
    setIsAddingRow(true);
    setIsEditing(false); // Ensure the whole table is not editable
  };

  const handleEditDetails = () => {
    setIsEditing(true);
    setIsAddingRow(false); // Ensure no new row is being added
  };

  const handleSave = async () => {
    try {
      if (isAddingRow) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/transaction/create-transaction`,
          {
            projectId: currentProject.id,
            paymentMode: newTransaction.paymentMode,
            amountPaid: newTransaction.amountPaid,
            paidOn: newTransaction.paidOn,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 201) {
          toast.success("Transaction added successfully.");
          setNewTransaction({ paymentMode: "", amountPaid: "", paidOn: "" });
          setIsAddingRow(false);
          getTransactions(currentProject.id);
          getProjects();
        }
      } else if (isEditing) {
        // Handle saving edits for the entire table
        // Implement your save logic here
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/edit-transactions`,
            { transactions },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (response.status === 200) {
            getProjects();
            getTransactions(currentProject?.id);
            setIsEditing(false);
            toast.success("Transactions updated successfully.");
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occurred.");
    }
  };

  useEffect(() => {
    getProjects();
  }, [searchTerm, currentDepartment]);

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <>
      <div className="mr-4 bg-white rounded-2xl pb-3 px-2 flex flex-col">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-medium text-[#625FFF]">Projects</h2>
          <div className="flex justify-end items-center gap-x-2">
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="search project"
              className=" text-[12px] py-4 px-2 outline-none bg-white text-[#A9A9A9]"
            />
            <div className="text-[12px] py-4 px-2 outline-none bg-white text-[#A9A9A9]">
              <select
                value={currentDepartment}
                onChange={(e) => setCurrentDepartment(e.target.value)}
                className=" outline-none"
              >
                <option value="" disabled selected>Select</option>
                {departments.map((department) => (
                  <option key={department.name}>{department.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="h-[42vh] mr-4">
          <div>
            <div className="px-4 py-3 bg-[#DEDEF4] rounded-t-md grid grid-cols-4">
              <div className="text-[14px] text-[#625FFF] font-medium flex justify-center items-center">
                Project
              </div>
              <div className="text-[14px] text-[#625FFF] font-medium flex justify-center items-center">
                Department
              </div>
              <div className="text-[14px] text-[#625FFF] font-medium flex justify-center items-center">
                payment Status
              </div>
              <div className="text-[14px] text-[#625FFF] font-medium flex justify-center items-center">
                Action
              </div>
            </div>

            <div
              className=" h-[34vh] overflow-y-auto"
              style={{ scrollbarWidth: "none" }}
            >
              {projects?.map((project, index) => (
                <div
                  key={index}
                  className="py-2 px-4 rounded-t-md border-b grid grid-cols-4"
                >
                  <div className="text-[12px] font-medium flex justify-center items-center">
                    {project?.projectName}
                  </div>
                  <div className="text-[12px] font-medium flex justify-center items-center">
                    {project?.departmentName}
                  </div>
                  <div
                    className={`text-[12px] flex justify-center items-center ${
                      calculatePaymentStatus(project) === "Paid in full" ||
                      calculatePaymentStatus(project) === "Partially Paid"
                        ? "text-[#3FFD3B]"
                        : "text-[#F72585]"
                    }`}
                  >
                    {calculatePaymentStatus(project)}
                  </div>
                  <div
                    className="text-[12px] font-medium flex justify-center items-center text-[#625FFF] cursor-pointer"
                    onClick={() => {
                      setCurrentProject(project);
                      getTransactions(project?.id);
                    }}
                  >
                    View Details
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mr-4 bg-white rounded-2xl pb-3 px-2 flex flex-col mt-2">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-medium text-[#625FFF]">
            Payment Details - {currentProject?.projectName}
          </h2>
        </div>
        <div>
          <div className="px-4 py-3 bg-[#DEDEF4] rounded-t-md text-[#625FFF] grid grid-cols-6">
            <div className="w-full flex justify-center items-center">
              <span className="text-[12px] font-medium">Sr. no.</span>
            </div>
            <div className="w-full flex justify-center items-center">
              <span className="text-[12px] font-medium">
                Total Project Cost
              </span>
            </div>
            <div className="w-full flex justify-center items-center">
              <span className="text-[12px] font-medium">Payment Mode</span>
            </div>
            <div className="w-full flex justify-center items-center">
              <span className="text-[12px] font-medium">Amount Paid</span>
            </div>
            <div className="w-full flex justify-center items-center">
              <span className="text-[12px] font-medium">Paid On</span>
            </div>
            <div className="w-full flex justify-center items-center">
              <span className="text-[12px] font-medium">Due Date</span>
            </div>
          </div>
          <div
            className="h-[17vh] overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            <div className="py-2 px-4 grid grid-cols-6 border-b">
              <div className="w-full flex justify-center items-center">
                <span className="text-[12px] font-medium">1.</span>
              </div>
              <div className="w-full flex justify-center items-center">
                <span className="text-[12px] font-medium">
                  {currentProject?.projectCost}
                </span>
              </div>
              <div className="w-full flex justify-center items-center">
                <span className="text-[12px]"></span>
              </div>
              <div className="w-full flex justify-center items-center">
                <span className="text-[12px]"></span>
              </div>
              <div className="w-full flex justify-center items-center">
                <span className="text-[12px]"></span>
              </div>
              <div className="w-full flex justify-center items-center">
                <span className="text-[12px]">
                  {currentProject?.dueDate?.slice(0, 10)}
                </span>
              </div>
            </div>
            {transactions &&
              transactions?.map((transaction, index) => (
                <div
                  key={index}
                  className="py-2 px-4 grid grid-cols-6 border-b"
                >
                  <div className="w-full flex justify-center items-center">
                    <span className="text-[12px] font-medium">
                      {index + 2}.
                    </span>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <span className="text-[12px] font-medium">
                      {transaction?.projectCost}
                    </span>
                  </div>
                  <div className="w-full flex justify-center items-center">
                    {isEditing ? (
                      <input
                        type="text"
                        value={transaction.paymentMode}
                        onChange={(e) =>
                          handleTransactionChange(e, index, "paymentMode")
                        }
                        className="text-[12px] outline-none border-b border-gray-300"
                      />
                    ) : (
                      <span className="text-[12px]">
                        {transaction?.paymentMode}
                      </span>
                    )}
                  </div>
                  <div className="w-full flex justify-center items-center">
                    {isEditing ? (
                      <input
                        type="number"
                        value={transaction.amountPaid}
                        onChange={(e) =>
                          handleTransactionChange(e, index, "amountPaid")
                        }
                        className="text-[12px] outline-none border-b border-gray-300"
                      />
                    ) : (
                      <span className="text-[12px]">
                        {transaction?.amountPaid}
                      </span>
                    )}
                  </div>
                  <div className="w-full flex justify-center items-center">
                    {isEditing ? (
                      <input
                        type="date"
                        value={transaction.paidOn?.slice(0, 10)}
                        onChange={(e) =>
                          handleTransactionChange(e, index, "paidOn")
                        }
                        className="text-[12px] outline-none border-b border-gray-300"
                      />
                    ) : (
                      <span className="text-[12px]">
                        {transaction?.paidOn.slice(0, 10)}
                      </span>
                    )}
                  </div>
                  <div className="w-full flex justify-center items-center">
                    <span className="text-[12px]">
                      {transaction?.dueDate?.slice(0, 10)}
                    </span>
                  </div>
                </div>
              ))}
            {isAddingRow && (
              <div className="py-2 px-4 grid grid-cols-6 border-b">
                <div className="w-full flex justify-center items-center">
                  <span className="text-[12px] font-medium">
                    {transactions.length + 2}.
                  </span>
                </div>
                <div className="w-full flex justify-center items-center">
                  <span className="text-[12px] font-medium"></span>
                </div>
                <div className="w-full flex justify-center items-center">
                  <input
                    type="text"
                    placeholder="Payment Mode"
                    className="text-[12px] outline-none border-b border-gray-300"
                    value={newTransaction.paymentMode}
                    onChange={(e) =>
                      setNewTransaction({
                        ...newTransaction,
                        paymentMode: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-full flex justify-center items-center">
                  <input
                    type="number"
                    placeholder="Amount Paid"
                    className="text-[12px] outline-none border-b border-gray-300"
                    value={newTransaction.amountPaid}
                    onChange={(e) =>
                      setNewTransaction({
                        ...newTransaction,
                        amountPaid: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-full flex justify-center items-center">
                  <input
                    type="date"
                    className="text-[12px] outline-none border-b border-gray-300"
                    value={newTransaction.paidOn?.slice(0, 10)}
                    onChange={(e) =>
                      setNewTransaction({
                        ...newTransaction,
                        paidOn: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-full flex justify-center items-center">
                  <span className="text-[12px]"></span>
                </div>
              </div>
            )}
            <div
              className="flex justify-center items-center py-2 cursor-pointer"
              onClick={handleAddRow}
            >
              <IoIosAdd className="h-6 w-6 text-[#585353] mr-2" />
              <span className="text-[12px] text-[#585353]">Add Row</span>
            </div>
          </div>
          <div className="flex justify-end items-center w-full mt-4">
            <button
              className="text-[#585353] text-[12px] py-2 px-8 border rounded-xl border-[#E7E1E1] mr-2"
              onClick={() => {
                setIsEditing(false);
                setIsAddingRow(false);
              }}
            >
              Cancel
            </button>
            <button
              className="text-white py-2 px-8 border font-light rounded-xl bg-[#625FFF] flex justify-center items-center"
              onClick={
                isEditing || isAddingRow ? handleSave : handleEditDetails
              }
            >
              <MdModeEditOutline className="h-4 w-4 mr-2" />
              <span className="text-[12px]">
                {isEditing || isAddingRow ? "Save" : "Edit Details"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounts;

