import React from "react";

const RequirementModal = ({ setshowRequirementsModal, requirements }) => {
  const openPDFInNewTab = () => {
    const blob = new Blob([new Uint8Array(requirements.requirementsPdf.data)], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-md p-8 w-full md:w-1/2">
        <h2 className="text-center">
          Requirement : ${requirements?.projectName}
        </h2>

        <div>
          <div className="flex w-full mb-2">
            <label className="w-[20%] p-4">Notes :</label>
            <div className="text-[#585353] bg-[#f8f8f8] w-[80%] p-4 rounded-xl">
              {requirements.note}
            </div>
          </div>

          <div className="flex w-full mb-2">
            <label className="w-[20%] p-4">PDF :</label>
            <p
              className="w-[80%] text-[#6360FF] underline p-4 cursor-pointer"
              onClick={openPDFInNewTab}
            >
              view.pdf
            </p>
          </div>

          <div className="flex w-full mb-2">
            <label className="w-[20%] p-4">URL :</label>
            <div className="w-[80%] h-auto cursor-pointer">
              <p className="break-all text-[#6360FF] underline p-4 cursor-pointer">
                {requirements.url}
              </p>
            </div>
          </div>

          <div className="float-right mb-2">
            <button
              className="bg-[#6360FF] text-white p-2 w-[100px] rounded-xl"
              onClick={() => setshowRequirementsModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequirementModal;
// #f8f8f8
