import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const TaskList = ({ setOpen, selectedProject }) => {
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [delayedTasks, setDelayedTasks] = useState([]);

  const getTasks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lead/task/project/${selectedProject}`
      );
      if (response.status === 200) {
        // setTasks(response.data.tasks);
        setCompletedTasks(response?.data?.completedTasks);
        setDelayedTasks(response?.data?.delayedTasks);
        setTasks(response?.data?.tasks);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };  

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-10">
      <div className="bg-white rounded-xl p-4 w-[80%] h-[95%] ">
        {/* ************  Total Task *********** */}
        <div className="border mb-2 rounded-md h-[30%]">
          <h1 className="bg-[#dedef3] text-[#625fff] h-[25%] p-2 text-center">
            Total Tasks {tasks.length}
          </h1>
          <div
            className="h-[75%] overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            <div className="grid grid-cols-3 text-center border-b border-[#D9D9D9] py-1 bg-[#f8f8f8]">
              <div>
                <span>Task No.</span>
              </div>
              <div>
                <span>Task Title</span>
              </div>
              <div>
                <span>Assigned Employee</span>
              </div>
            </div>
            {tasks.map((task, index) => (
              <div className="grid grid-cols-3 text-center border-b border-[#D9D9D9] bg-[#f8f8f8] py-1">
                <div>
                  <span>{index + 1}</span>
                </div>
                <div>
                  <span>{task?.name}</span>
                </div>
                <div>
                  {task?.employees?.map((employee) => (
                    <span key={employee?.id}>{employee?.name},</span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* ******** delayed Task ************** */}
        <div className="border mb-2 rounded-md h-[30%]">
          <h1 className="bg-[#fccfe4] text-[#f72f8b] h-[25%] p-2 text-center">
            Delayed Tasks {delayedTasks?.length}
          </h1>
          <div
            className="h-[75%] overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            <div className="grid grid-cols-3 text-center border-b border-[#D9D9D9] py-1 bg-[#f8f8f8]">
              <div>
                <span>Task No.</span>
              </div>
              <div>
                <span>Task Title</span>
              </div>
              <div>
                <span>Assigned Employee</span>
              </div>
            </div>
            {delayedTasks?.map((task, index) => (
              <div className="grid grid-cols-3 text-center border-b border-[#D9D9D9] bg-[#f8f8f8] py-1">
                <div>
                  <span>{index + 1}</span>
                </div>
                <div>
                  <span>{task?.name}</span>
                </div>
                <div>
                  {task?.employees?.map((employee) => (
                    <span key={employee?.id}>{employee?.name},</span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* ************  In Progress Task ********** */}

        <div className="border mb-2 rounded-md h-[30%]">
          <h1 className="bg-[#cbffca] text-[#3ffd3b] h-[25%] p-2 text-center">
            In Progress Tasks {completedTasks.length}
          </h1>
          <div
            className="h-[75%] overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            <div className="grid grid-cols-3 text-center border-b border-[#D9D9D9] py-1 bg-[#f8f8f8]">
              <div>
                <span>Task No.</span>
              </div>
              <div>
                <span>Task Title</span>
              </div>
              <div>
                <span>Assigned Employee</span>
              </div>
            </div>
            {completedTasks?.map((task, index) => (
              <div className="grid grid-cols-3 text-center border-b border-[#D9D9D9] bg-[#f8f8f8] py-1">
                <div>
                  <span>{index + 1}</span>
                </div>
                <div>
                  <span>{task?.name}</span>
                </div>
                <div>
                  {task?.employees?.map((employee) => (
                    <span key={employee?.id}>{employee?.name},</span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          className="float-right border p-2 w-[100px] bg-[#625fff] text-white rounded-xl"
          onClick={() => setOpen(false)}
        >
          close
        </button>
      </div>
    </div>
  );
};

export default TaskList;
