// import React,{useState} from 'react'
// import link from "../../assets/link.png"
// import { RiDeleteBin6Line } from "react-icons/ri";
// import { MdOutlineEdit } from "react-icons/md";
// import { VscEyeClosed } from "react-icons/vsc";
// import { VscEye } from "react-icons/vsc";
// const EditEmployeeModal = ({viewEmployeeModal,employee}) => {
//     const [formData,setFormData]=useState(employee)
//     const [showPassword,setShowPassword]=useState(false)
//     const [showConfirmPassword,setShowConfirmPassword]=useState(false)

//     const [errors, setErrors] = useState({});
//       const handleChange=(e)=>{
//         const {name,value,files,type}=e.target;

//         if (name === "resume") {
//           setFormData((prevData) => ({
//             ...prevData,
//             [name]: files[0] // Only taking the first file if multiple files are selected
//           }));
//         }
//         else if (type === "radio" && name === "teamlead") {
//           // For radio buttons, set the value directly
//           setFormData((prevData) => ({
//             ...prevData,
//             teamLead: value === "on" // Check if the radio button is checked
//           }));
//         }
//         else {
//           setFormData((prevData) => ({
//             ...prevData,
//             [name]: value
//           }));
//         }
//       }
//       const handleImageChange = (event) => {
//         const { files } = event.target;

//         setFormData((prevData) => ({
//           ...prevData,
//           profile: {
//             ...prevData.profile,
//             image: files[0]
//           },
//         }));
//       };

//   const handleSubmit=(e)=>{
//     e.preventDefault();
//     let errors = {};
//     const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d)(?!.*[!@#$%^&*].*[!@#$%^&*])(?!.*[A-Z].*[A-Z]).{8,}$/;

//     if (!formData.contact || !/^\d{10}$/.test(formData.contact)) {
//       errors.contact = 'Please enter a valid 10-digit contact number';
//     }
//     if (!passwordRegex.test(formData.password)) {
//       errors.password = 'Password must contain at least one capital letter, one special character, one digit, and be at least 8 characters long';
//     }

//     if (!formData.email || !/^\S+@\S+\.\S+$/.test(formData.email)) {
//       errors.email = 'Please enter a valid email address';
//     }

//     if (formData.password !== formData.reEnterPassword) {
//       alert("Password and confirm Password do not match");
//       return;
//     }
//     if (Object.keys(errors).length > 0) {
//       setErrors(errors);
//       if (errors.contact) {
//         alert(errors.contact);
//       } else if (errors.password) {
//         alert(errors.password);
//       } else if (errors.email) {
//         alert(errors.email);
//       }

//     } else {
//       console.log(formData);
//       // Add logic here to update the employee details
//       viewEmployeeModal();
//     }

//   }
//   return (
//     <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
//               <div className="bg-white p-6 rounded-lg w-[500px] h-[500px] overflow-y-auto">
//                 <h1 className='font-semibold mb-4'>Edit Employee Details</h1>
//                 <form onSubmit={handleSubmit}>

//                  {/* *** NAme *** */}
//                   <div className='w-full flex flex-col gap-2'>
//                     <label className=''>Employee Name</label>
//                     <input placeholder="Enter Employee Name" name='employeeName' value={formData.employeeName} onChange={handleChange}  className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4" />
//                   </div>

//                   {/* *** position **** */}

//                   <div className='w-full flex flex-col gap-2'>
//                     <label className=''>Position</label>
//                     <input placeholder="Enter position" name='position' value={formData.position} onChange={handleChange}  className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4" />
//                   </div>

//                   {/* *** Team Lead *** */}
//                   <div className=''>
//                     <input type="radio" name="teamlead" checked={formData.teamLead} onChange={handleChange} className='mr-2'  />
//                     <label className=''>Add as Team Lead</label>
//                   </div>

//                   {/* *** Birth and Blood *** */}
//                   <div className="w-full flex gap-4 mt-2 mb-4">
//                     <div className='w-[50%]'>
//                       <label>Date of Birth</label> <br/>
//                       <input type="date" name="birthDate" value={formData.birthDate} onChange={handleChange}    className="bg-[#f1f1f1] p-2 rounded-md w-full" />
//                     </div>
//                     <div className='w-[50%]'>
//                       <label>Blood Group</label> <br/>
//                       <input placeholder="Enter Blood Group" name="bloodGroup" onChange={handleChange}   value={formData.bloodGroup} className="bg-[#f1f1f1] p-2 rounded-md w-full"/>
//                     </div>
//                   </div>

//                   {/* *** Profile *** */}
//                   <div className='w-full mb-4 '>
//                     <label className=''>Upload Profile</label> <br/>
//                     <input type="file" id="upload" accept="image/*" onChange={handleImageChange}   className="hidden" />

//                     <label htmlFor="upload" className="flex  gap-2">
//                       <input placeholder="upload Profile" name="profile" value={formData.profile.image ? formData.profile.image.name : ''}   className="bg-[#f1f1f1] p-2 rounded-md w-[75%]" readOnly />
//                       <span className="bg-[#625FFF] p-2 flex gap-1 rounded-md text-white w-[25%]"><img src={link} className="w-[15px] h-[20px]" alt="Upload Icon"  />
//                       Upload</span>
//                     </label>

//                     {
//                       formData.profile.image ? (
//                         <div className='flex gap-2 mt-2'>
//                           {formData.profile.image.name}-uploaded  <p className='flex gap-2 mt-1'><MdOutlineEdit/> <RiDeleteBin6Line color='red' cursor='pointer' /></p>

//                         </div>
//                       ):(
//                         <></>
//                       )
//                     }
//                   </div>

//                   {/* *** Mobile *** */}
//                   <div className='w-full mb-4 '>
//                     <label>Mobile Number</label> <br/>
//                     <input placeholder="Enter contact" name="contact" onChange={handleChange}   value={formData.contact}  className="bg-[#f1f1f1] p-2 rounded-md w-full" />
//                   </div>

//                   {/* *** Resedential Address *** */}

//                   <div className='w-full mb-4'>
//                     <label>Resedential Address</label> <br/>
//                     <input placeholder="enter Resedential Address" name="residentialAddress"  onChange={handleChange}   value={formData.residentialAddress} className="bg-[#f1f1f1] p-2 rounded-md w-full"/>
//                   </div>

//                   {/* *** Permanent Address *** */}

//                   <div className='w-full mb-4'>
//                     <label>Permanent Address</label> <br/>
//                     <input placeholder="enter Permanent Address" name="permanentAddress" onChange={handleChange}   value={formData.permanentAddress}  className="bg-[#f1f1f1] p-2 rounded-md w-full" />
//                   </div>

//                   {/* *** Email Address *** */}

//                   <div className='w-full mb-4'>
//                     <label>Email </label> <br/>
//                     <input placeholder="Enter Email" type="email" name="email" onChange={handleChange}   value={formData.email} className="bg-[#f1f1f1] p-2 rounded-md w-full" />
//                   </div>

//                   {/* *** Password  *** */}

//                   <div className='w-full mb-4 relative'>
//                     <label>Password</label> <br/>
//                     <input placeholder="Enter password" type={showPassword?'text':'password'} name="password"  onChange={handleChange}   value={formData.password}  className="bg-[#f1f1f1] p-2 rounded-md w-full" />
//                     <p className='absolute right-2 top-[35px]  cursor-pointer' onClick={()=>setShowPassword(!showPassword)}>{showPassword?<VscEye/>:<VscEyeClosed/>}</p>
//                   </div>

//                   {/* *** Re-enter Password  *** */}

//                   <div className='w-full mb-4 relative'>
//                     <label>Re-enter Password</label><br/>
//                     <input placeholder="Re-Enter Password" type={showConfirmPassword?'text':'password'} name="reEnterPassword" onChange={handleChange}   value={formData.reEnterPassword}  className="bg-[#f1f1f1] p-2 rounded-md w-full" />
//                     <p className='absolute right-2 top-[35px]  cursor-pointer' onClick={()=>setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword?<VscEye/>:<VscEyeClosed/>}</p>
//                   </div>

//                   {/* *** Resume *** */}
//                   <div className='w-full mb-4'>
//                     <label>Attach Resume</label> <br/>
//                     <input type="file" id="resume"   onChange={handleChange} name="resume"   className="hidden" />

//                     <label htmlFor="resume" className="flex gap-2">
//                       <input placeholder="upload Profile" readOnly value={formData.resume?.name || ""}  className="bg-[#f1f1f1] p-2 rounded-md w-[70%]" />
//                       <span className="bg-[#625FFF] p-2 rounded-md flex gap-1 text-white w-[30%]"><img src={link} className="w-[15px] h-[20px]" alt="Upload Icon"  />
//                       Attach pdf</span>
//                     </label>

//                     {
//                       formData.resume ? (
//                         <div className='flex gap-2 mt-2'>
//                           {formData.resume.name}-uploaded  <p className='flex gap-2 mt-1'><MdOutlineEdit/> <RiDeleteBin6Line color='red' cursor='pointer' /></p>

//                         </div>
//                       ):(
//                         <></>
//                       )
//                     }
//                   </div>

//                   <div className="float-right py-2">
//                     <button className="border mr-2 w-[120px] p-2" onClick={viewEmployeeModal}>Go Back</button>
//                     <button className="text-white bg-[#625FFF] p-2 " >Save Changes</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//   )
// }

// export default EditEmployeeModal

// ************** New ******************

import React, { useEffect, useState } from "react";
import link from "../../assets/link.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";
import { VscEyeClosed } from "react-icons/vsc";
import { VscEye } from "react-icons/vsc";
import { toast } from "react-toastify";
import axios from "axios";

const EditEmployeeModal = ({ setShow, getEmployees, departments }) => {
  const [formData, setFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value, checked, files } = e.target;

    if (name === "resumePdf") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // Only taking the first file if multiple files are selected
      }));
    } else if (name === "isTeamLead") {
      // For radio buttons, set the value directly
      console.log(name, value);
      setFormData((prevData) => ({
        ...prevData,
        isTeamLead: checked, // Set the value based on whether the checkbox is checked
      }));
      console.log(formData.isTeamLead);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleImageChange = (event) => {
    const { files } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      profile: {
        ...prevData.profile,
        profileImage: files[0],
      },
    }));
  };

  console.log(formData?.isTeamLead);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d)(?!.*[!@#$%^&*].*[!@#$%^&*])(?!.*[A-Z].*[A-Z]).{8,}$/;

    if (
      (formData.mobileNumber && !formData.mobileNumber) ||
      !/^\d{10}$/.test(formData.mobileNumber)
    ) {
      errors.mobileNumber = "Please enter a valid 10-digit contact number";
    }
    if (formData.password && !passwordRegex.test(formData.password)) {
      errors.password =
        "Password must contain at least one capital letter, one special character, one digit, and be at least 8 characters long";
    }

    if (
      (formData.email && !formData.email) ||
      !/^\S+@\S+\.\S+$/.test(formData.email)
    ) {
      errors.email = "Please enter a valid email address";
    }

    if (formData?.password !== formData?.reEnterPassword) {
      toast.error("Password and confirm Password do not match");
      return;
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      if (errors.contact) {
        toast.error(errors.contact);
      } else if (errors.password) {
        toast.error(errors.password);
      } else if (errors.email) {
        toast.error(errors.email);
      }
    } else {
      await editEmployee();
    }
  };

  const editEmployee = async () => {
    try {
      const response = await axios.put(
        `${
          process.env.REACT_APP_API_URL
        }/admin/employee/edit-employee/${localStorage.getItem(
          "editEmployeeId"
        )}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Employee updated.");
        setShow(false);
        getEmployees();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const getEmployee = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/employee/${localStorage.getItem(
          "editEmployeeId"
        )}`
      );
      if (response.status === 200) {
        const data = response?.data?.employee;
        delete data["password"];
        delete data["profileImageContentType"];
        delete data["profileImageData"];
        delete data["resumePdfContentType"];
        delete data["resumePdfData"];
        data["profileImage"] = null;
        data["resumePdf"] = null;
        setFormData(data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occured");
    }
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-[500px] h-[500px] overflow-y-auto">
        <h1 className="font-semibold mb-4">Edit Employee Details</h1>
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col gap-2">
            <label className="">Employee Name</label>
            <input
              placeholder="Enter Employee Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>

          <div className="w-full flex flex-col gap-2">
            <label className="">Department</label>
            <select
              onChange={handleChange}
              name="departmentName"
              value={formData.departmentName}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            >
              <option value="" disabled selected>Select</option>
              {departments?.map((department) => (
                <option>{department.name}</option>
              ))}
            </select>
          </div>

          <div className="w-full flex flex-col gap-2">
            <label className="">Position</label>
            <input
              placeholder="Enter position"
              name="position"
              value={formData.position}
              onChange={handleChange}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>

          <div className="">
            <input
              type="checkbox"
              name="isTeamLead"
              checked={
                formData.isTeamLead === "TEAMLEAD" ||
                formData.isTeamLead === "TEAMMEMBER"
                  ? formData.isTeamLead === "TEAMLEAD"
                  : formData.isTeamLead
                  ? true
                  : false
              }
              onChange={handleChange}
              className="mr-2"
            />
            <label className="">Add as Team Lead</label>
          </div>

          <div className="w-full flex gap-4 mt-2 mb-4">
            <div className="w-[50%]">
              <label>Date of Birth</label> <br />
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth?.slice(0, 10)}
                onChange={handleChange}
                className="bg-[#f1f1f1] p-2 rounded-md w-full"
              />
            </div>
            <div className="w-[50%]">
              <label>Blood Group</label> <br />
              <input
                placeholder="Enter Blood Group"
                name="bloodGroup"
                onChange={handleChange}
                value={formData.bloodGroup}
                className="bg-[#f1f1f1] p-2 rounded-md w-full"
              />
            </div>
          </div>
          <div className="w-full mb-4 ">
            <label className="">Upload Profile</label> <br />
            <input
              type="file"
              id="upload"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
            />
            <label htmlFor="upload" className="flex  gap-2">
              <input
                placeholder="upload Profile"
                name="profileImage"
                value={
                  formData?.profileImage
                    ? formData?.profileImage?.image.name
                    : ""
                }
                className="bg-[#f1f1f1] p-2 rounded-md w-[75%]"
                readOnly
              />
              <span className="bg-[#625FFF] p-2 flex gap-1 rounded-md text-white w-[25%]">
                <img
                  src={link}
                  className="w-[15px] h-[20px]"
                  alt="Upload Icon"
                />
                Upload
              </span>
            </label>
            {formData?.profileImage?.image ? (
              <div className="flex gap-2 mt-2">
                {formData?.profileImage?.image?.name}-uploaded{" "}
                <p className="flex gap-2 mt-1">
                  <MdOutlineEdit />{" "}
                  <RiDeleteBin6Line color="red" cursor="pointer" />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="w-full mb-4 ">
            <label>Mobile Number</label> <br />
            <input
              placeholder="Enter contact"
              name="mobileNumber"
              onChange={handleChange}
              value={formData.mobileNumber}
              className="bg-[#f1f1f1] p-2 rounded-md w-full"
            />
          </div>

          <div className="w-full mb-4">
            <label>Residential Address</label> <br />
            <input
              placeholder="enter Resedential Address"
              name="residentialAddress"
              onChange={handleChange}
              value={formData.residentialAddress}
              className="bg-[#f1f1f1] p-2 rounded-md w-full"
            />
          </div>

          <div className="w-full mb-4">
            <label>Permanent Address</label> <br />
            <input
              placeholder="enter Permanent Address"
              name="permanentAddress"
              onChange={handleChange}
              value={formData.permanentAddress}
              className="bg-[#f1f1f1] p-2 rounded-md w-full"
            />
          </div>

          <div className="w-full mb-4">
            <label>Email </label> <br />
            <input
              placeholder="Enter Email"
              type="email"
              name="email"
              onChange={handleChange}
              value={formData.email}
              className="bg-[#f1f1f1] p-2 rounded-md w-full"
            />
          </div>

          <div className="w-full mb-4 relative">
            <label>Password</label> <br />
            <input
              placeholder="Enter password"
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={handleChange}
              value={formData.password}
              className="bg-[#f1f1f1] p-2 rounded-md w-full"
            />
            <p
              className="absolute right-2 top-[35px]  cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VscEye /> : <VscEyeClosed />}
            </p>
          </div>

          <div className="w-full mb-4 relative">
            <label>Re-enter Password</label>
            <br />
            <input
              placeholder="Re-Enter Password"
              type={showConfirmPassword ? "text" : "password"}
              name="reEnterPassword"
              onChange={handleChange}
              value={formData.reEnterPassword}
              className="bg-[#f1f1f1] p-2 rounded-md w-full"
            />
            <p
              className="absolute right-2 top-[35px]  cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <VscEye /> : <VscEyeClosed />}
            </p>
          </div>

          <div className="w-full mb-4">
            <label>Attach Resume</label> <br />
            <input
              type="file"
              id="resumePdf"
              onChange={handleChange}
              name="resumePdf"
              className="hidden"
            />
            <label htmlFor="resumePdf" className="flex gap-2">
              <input
                placeholder="upload Profile"
                readOnly
                value={formData.resumePdf?.name || ""}
                className="bg-[#f1f1f1] p-2 rounded-md w-[70%]"
              />
              <span className="bg-[#625FFF] p-2 rounded-md flex gap-1 text-white w-[30%]">
                <img
                  src={link}
                  className="w-[15px] h-[20px]"
                  alt="Upload Icon"
                />
                Attach pdf
              </span>
            </label>
            {formData.resumePdf ? (
              <div className="flex gap-2 mt-2">
                {formData.resumePdf.name}-uploaded{" "}
                <p className="flex gap-2 mt-1">
                  <MdOutlineEdit />{" "}
                  <RiDeleteBin6Line color="red" cursor="pointer" />
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="float-right py-2">
            <button
              className="border mr-2 w-[120px] p-2"
              onClick={() => setShow(false)}
            >
              Go Back
            </button>
            <button className="text-white bg-[#625FFF] p-2 ">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEmployeeModal;
