import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../teamLead/Lead.css";
import NewTask from "../../components/TeamLead/NewTask";
import SearchComponent from "../../components/TeamLead/SearchComponent";
import { toast } from "react-toastify";
import axios from "axios";
import { CiSearch } from "react-icons/ci";

const LeadDashboard = () => {
  const [projectStats, setProjectStats] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const getTeamLeadProjectTaskStats = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/teamlead-project-stats?teamLead=${localStorage.getItem(
          "userId"
        )}&searchTerm=${searchTerm}`
      );
      if (response.status === 200) {
        setProjectStats(response.data.leadProject);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getTeamLeadProjectTaskStats();
  }, [searchTerm]);

  return (
    <div className="overflow-hidden md:pl-2 px-2 pt-4">
      <NewTask />
      <div className="h-[52vh] mt-6 mr-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-semibold text-[#625FFF]">
            Ongoing Projects
          </h2>
          <div className="flex justify-end items-center gap-x-2">
            <div className="relative">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                placeholder="search project"
                className=" text-[14px] py-3 px-6 outline-none border rounded-md bg-white text-[#A9A9A9]"
              />
              <div className="absolute top-3 px-1 text-[#A9A9A9]">
                <CiSearch size={20} />
              </div>
            </div>

            <div className="text-[12px] py-3 px-2 outline-none bg-[#625FFF] text-white rounded-md">
              Department:
            </div>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-6 text-center py-4 px-4 bg-[#DEDEF4] rounded-t-md">
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Sr No.
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Client
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Team Lead
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Project
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Total Tasks
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Completed Task
            </span>
          </div>

          <div
            className=" h-[34vh] bg-white overflow-y-auto rounded-bl-xl rounded-br-xl"
            style={{ scrollbarWidth: "none" }}
          >
            {projectStats?.map((data, index) => (
              <div
                className="grid grid-cols-6 text-center py-4 px-4  rounded-t-md cursor-pointer"
                onClick={() => navigate(`/view-task/${data.id}`)}
              >
                <span className="text-[16px] font-semibold">{index + 1}</span>
                <span className="text-[16px] font-semibold">
                  {data.clientName}
                </span>
                <span className="text-[16px] font-semibold">
                  {data.teamLeadName}
                </span>
                <span className="text-[16px] font-semibold">
                  {data.projectName}
                </span>
                <span className="text-[16px] font-semibold">
                  {data.totalTasks}
                </span>
                <span className="text-[16px] font-semibold">
                  {data.completedTasks}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadDashboard;
