import { FaRegSquare } from "react-icons/fa6";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { IoIosAddCircle } from "react-icons/io";
import { RiTeamFill } from "react-icons/ri";

import profileImage from "../../assets/teamLead/leadprofile.png";
import { Link, useLocation } from "react-router-dom";
// import Profile from "../modal/Profile";
import { useEffect, useState } from "react";
import TeamLeadProfile from "../../modal/teamLead/TeamLeadProfile";
import { toast } from "react-toastify";
import axios from "axios";

const TeamLeadSidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [showProfile, setShowProfile] = useState(false);
  const [user, setUser] = useState({});

  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/get-user`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {showProfile && (
        <TeamLeadProfile
          setShowProfile={setShowProfile}
          user={user}
          getUser={getUser}
        />
      )}
      <div className="absolute top-0 left-0 h-screen w-1/5 flex justify-center items-center">
        <div className="bg-white mt-8 w-[90%] md:w-[88%] h-[88vh] flex flex-col items-center rounded-2xl">
          <div className="flex pt-7 items-center">
            <FaRegSquare className="h-6 w-8 font-extrabold text-[#625FFF] mr-2" />{" "}
            <span className="font-bold tracking-widest">MRR</span>
          </div>
          <div className="flex flex-col items-center mt-12">
            <img
              src={
                !user.profileImageData
                  ? profileImage
                  : `data:${user?.profileImageContentType};base64,${user?.profileImageData}`
              }
              alt="profile image"
              className=" h-16 w-16 rounded-full"
            />
            <button
              className="font-bold text-[12px] text-white bg-[#625FFF] mt-3 px-4 py-2 rounded-md"
              onClick={() => setShowProfile(true)}
            >
              My Profile
            </button>
          </div>
          <div className="mt-12 flex flex-col justify-start gap-y-3">
            <Link
              to="/"
              className={`flex items-center border-l-4 rounded-sm pl-4 ${
                pathname === "/" ? "border-[#625FFF]" : "border-white"
              }`}
            >
              <TbLayoutDashboardFilled
                className={`mr-2 h-5 w-5 ${
                  pathname === "/" ? "text-[#625FFF]" : "border-white"
                }`}
              />{" "}
              <span className={`${pathname === "/" ? "text-[#625FFF]" : ""}`}>
                Dashboard
              </span>
            </Link>
            <Link
              to="/create-task"
              className={`flex items-center border-l-4 rounded-sm pl-4 ${
                pathname === "/create-task"
                  ? "border-[#625FFF]"
                  : "border-white"
              }`}
            >
              <IoIosAddCircle
                className={`mr-2 h-5 w-5 ${
                  pathname === "/create-task"
                    ? "text-[#625FFF]"
                    : "border-white"
                }`}
              />{" "}
              <span
                className={`${
                  pathname === "/create-task" ? "text-[#625FFF]" : ""
                }`}
              >
                Create Task
              </span>
            </Link>
            <Link
              to="/team"
              className={`flex items-center border-l-4 $ border-[#625FFF] rounded-sm pl-4 ${
                pathname === "/team" ? "border-[#625FFF]" : "border-white"
              }`}
            >
              <RiTeamFill
                className={`mr-2 h-5 w-5 ${
                  pathname === "/team" ? "text-[#625FFF]" : "border-white"
                }`}
              />{" "}
              <span
                className={`${pathname === "/team" ? "text-[#625FFF]" : ""}`}
              >
                Team
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamLeadSidebar;
