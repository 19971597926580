import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import link from "../../assets/link.png";

const AddProject = () => {
  const [departments, setDepartments] = useState([]);
  const [teamLeads, setTeamLeads] = useState([]);
  const [nonTeamLeads, setNonTeamLeads] = useState([]);
  const [teamLeadSearch, setTeamLeadSearch] = useState({});
  const [teamLeadSearchResults, setTeamLeadSearchResults] = useState([]);
  const [empInputs, setEmpInputs] = useState([{ id: 0, name: "" }]);
  const [searchResults, setSearchResults] = useState([]);
  const [employeeSearchTerm, setemployeeSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    departmentName: "",
    projectName: "",
    startDate: "",
    endDate: "",
    requirements: "",
    clientName: "",
    clientNumber: "",
    clientMail: "",
    teamLead: {},
    teamMembers: [],
    projectCost: "",
    paymentMode: "",
    dueDate: "",
    requirementsPdf: null,
  });

  const handleTeamLeadSearch = (query) => {
    setTeamLeadSearch(query);
    if (query) {
      // Here you can perform the search logic, for simplicity, I'll just filter a static list of names
      const searchResults = teamLeads.filter((lead) =>
        lead.name.toLowerCase().includes(query.toLowerCase())
      );
      setTeamLeadSearchResults(searchResults);
    } else {
      setTeamLeadSearchResults([]);
    }
  };

  const handleTeamLeadSelect = (lead) => {
    setFormData((prevData) => ({
      ...prevData,
      teamLead: { id: lead.id, name: lead.name },
    }));
    setTeamLeadSearchResults([]);
    setTeamLeadSearch(lead);
  };

  const handleInputChange = (index, value) => {
    const newInputs = [...empInputs];
    newInputs[index] = value;
    newInputs[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      employees: empInputs,
    }));
    setEmpInputs(newInputs);
    searchEmployee(index, value);
  };

  const searchEmployee = () => {
    const filterEmp = nonTeamLeads.filter((emp) =>
      emp?.name?.toLowerCase().includes(employeeSearchTerm.toLowerCase())
    );
    setSearchResults(filterEmp);
    if (!employeeSearchTerm) setSearchResults([]);
  };

  useEffect(() => {
    searchEmployee();
  }, [employeeSearchTerm]);

  const selectEmployee = (index, id, empName) => {
    const newInputs = [...empInputs];
    newInputs[index].id = id;
    newInputs[index].name = empName;
    setEmpInputs(newInputs);
    setSearchResults({ ...searchResults, [index]: [] });

    updateFormData("handleBy", {
      teamLead: teamLeadSearch,
      teamMembers: empInputs,
    });
  };

  // Function to update form data
  const updateFormData = (section, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: data,
    }));
  };

  // Function to handle "Done" button click
  const handleDoneClick = async () => {
    // Handle form submission or further processing
    const clientMail = formData.clientMail;
    const clientContact = formData.clientNumber;
    const projectCost = formData.projectCost;
    if (!validateContact(clientContact)) {
      toast.error("Please enter a valid contact number");
      return;
    } else if (!validateEmail(clientMail)) {
      toast.error("Please enter a valid email address");
      return;
    } else if (!validateProjectCost(projectCost)) {
      toast.error("Please enter a valid project cost");
      return;
    } else {
      // setNewData(formData);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/project/create-project`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 201) {
        toast.success("Project Added.");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured");
    }

    setFormData({
      departmentName: "",
      projectName: "",
      startDate: "",
      endDate: "",
      requirements: "",
      clientName: "",
      clientNumber: "",
      clientMail: "",
      teamLead: {},
      teamMembers: [],
      projectCost: "",
      paymentMode: "",
      dueDate: "",
      requirementsPdf: null,
    });
  };

  const validateContact = (contact) => {
    const contactRegex = /^\d{10}$/;
    return contactRegex.test(contact);
  };

  // Validation for email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateProjectCost = (cost) => {
    // Check if the cost is a positive number
    return /^\d+(\.\d{1,2})?$/.test(cost) && parseFloat(cost) > 0;
  };

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      requirementsPdf: file,
    }));
  };

  const getDepartments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/department`
      );
      if (response.status === 200) {
        setDepartments(response.data.departments);
      }
    } catch (error) {
      toast.error(error.response.data.message || "An error occured");
    }
  };

  const getTeamLeads = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/admin/team-leads`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setTeamLeads(response.data.teamLeads);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.responsive?.data?.message || "An error occured.");
    }
  };

  const getNonTeamLeads = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/admin/non-team-leads`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setNonTeamLeads(response?.data?.nonTeamLeads);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.responsive?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getDepartments();
    getTeamLeads();
    getNonTeamLeads();
  }, []);

  return (
    <div className="flex p-1 gap-4">
      <div className="w-[50%] h-[80vh] flex flex-col gap-2">
        <div className="w-full h-[55vh] bg-white rounded-2xl p-4 px-4 mt-1">
          <h1 className="mb-3 text-[#625FFF] font-semibold">Project Info</h1>
          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="w-[30%] ">Department :</label>
              <select
                name="departmentName"
                value={formData.departmentName}
                onChange={handleChange}
                className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              >
                <option value="" disabled selected>
                  Select
                </option>
                {departments?.map((department) => (
                  <option>{department?.name}</option>
                ))}
              </select>
            </div>

            <div className="flex justify-between items-center mb-2">
              <label className="w-[30%] ">Project Name :</label>
              <input
                type="text"
                name="projectName"
                onChange={handleChange}
                placeholder="Enter Project Name"
                className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>

            <div className="flex justify-between items-center mb-2">
              <label className="w-[30%] ">Start Date :</label>
              <input
                type="date"
                name="startDate"
                // value={formData.startDate}
                onChange={handleChange}
                className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>

            <div className="flex justify-between items-center mb-2">
              <label className="w-[30%] ">End Date :</label>
              <input
                type="date"
                name="endDate"
                // value={formData.endDate}
                onChange={handleChange}
                className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>

            <div className="flex justify-between items-center mb-2">
              <label className="w-[30%] ">Requirement :</label>
              <textarea
                placeholder="Type Here"
                name="requirements"
                // value={formData.requirements}
                onChange={handleChange}
                rows={2}
                className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              ></textarea>
            </div>

            <div className="float-right">
              <label htmlFor="upload" className="cursor-pointer">
                <span className="text-white flex gap-1 rounded-xl px-4 py-3 bg-[#625FFF] ">
                  <img src={link} className="w-[15px] h-[20px]" />
                  Attach Pdf
                </span>
              </label>
              <input type="file" id="upload" hidden onChange={handleFile} />
            </div>
          </div>
        </div>
        <div className="h-[45%] p-4 rounded-2xl mb-2 bg-white">
          <h1 className="mb-2 text-[#625FFF]  font-semibold">Accounts</h1>
          <div className="">
            <div className="flex gap-2 justify-between items-center mb-2">
              <label className="w-[30%]">Project Name :</label>
              <input
                placeholder="Enter Project name"
                name="projectName"
                value={formData.projectName}
                onChange={handleChange}
                className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>

            <div className="flex gap-2 justify-between items-center mb-2">
              <label className="w-[30%]">Project Cost :</label>
              <input
                placeholder="Rs. 20000/-"
                name="projectCost"
                // value={formData.projectCost}
                onChange={handleChange}
                className="w-[70%] p-1 px-2 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>

            <div className="flex gap-2 justify-between items-center mb-2">
              <label className="w-[30%]">Payment Mode:</label>
              <div className="w-[70%] flex justify-between p-1 px-2 gap-2">
                <input
                  type="radio"
                  id="Online"
                  name="paymentMode"
                  checked={formData.paymentMode === "Online"}
                  onChange={handleChange}
                  value="Online"
                  className=""
                />
                <label for="Online">Online</label>
                <input
                  type="radio"
                  id="Cash"
                  name="paymentMode"
                  checked={formData.paymentMode === "Cash"}
                  onChange={handleChange}
                  value="Cash"
                  className=""
                />
                <label for="Cash">Cash</label>
                <input
                  type="radio"
                  id="Both"
                  name="paymentMode"
                  checked={formData.paymentMode === "Both"}
                  onChange={handleChange}
                  value="Both"
                  className=""
                />
                <label for="Both">Both</label>
              </div>
            </div>

            <div className="flex gap-2 justify-between items-center mb-2">
              <label className="w-[30%]">Due Date:</label>
              <input
                type="date"
                name="dueDate"
                // value={formData.dueDate}
                onChange={handleChange}
                className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[50%]  h-[60vh]  rounded-2xl  mt-1">
        <div className="h-[45%] p-4 rounded-2xl mb-2 bg-white">
          <h1 className="mb-2 text-[#625FFF]  font-semibold">Client Info</h1>
          <div className="">
            <div className="flex gap-2 justify-between items-center mb-2">
              <label className="w-[30%]">Client Name :</label>
              <input
                name="clientName"
                // value={formData?.clientName}
                onChange={handleChange}
                placeholder="Enter client name"
                className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>

            <div className="flex gap-2 justify-between items-center mb-2">
              <label className="w-[30%]">Contact No :</label>
              <input
                placeholder="Ex. 9348848434"
                name="clientNumber"
                // value={formData.clientNumber}
                onChange={handleChange}
                className="w-[70%] p-1 px-2 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>

            <div className="flex gap-2 justify-between items-center mb-2">
              <label className="w-[30%]">Mail ID :</label>
              <input
                placeholder="Eg. myresearchroom.com"
                name="clientMail"
                // value={formData.clientMail}
                onChange={handleChange}
                className="w-[70%] p-1 px-2 border outline-none rounded-xl bg-[#f8f8f8]"
              />
            </div>
          </div>
        </div>
        <div className="flex h-[100%] flex-col justify-between ">
          <div className="h-[50%] p-4 rounded-2xl bg-white overflow-y-auto">
            <h1 className="mb-3 text-[#625FFF]  font-semibold">Handle By</h1>
            <div className="flex gap-2 justify-between items-center mb-2">
              <label className="w-[30%]">Team Lead :</label>
              <div className="w-[70%] relative">
                <input
                  type="text"
                  name="teamLeadSearch"
                  placeholder="Search here ..."
                  value={teamLeadSearch?.name}
                  onChange={(e) => {
                    handleTeamLeadSearch(e.target.value);
                  }}
                  className="p-1 w-full border outline-none rounded-xl bg-[#f8f8f8]"
                />

                {teamLeadSearch && teamLeadSearchResults.length > 0 && (
                  <ul className="h-[50px] overflow-y-auto">
                    {teamLeadSearchResults.map((lead, index) => (
                      <li
                        key={index}
                        onClick={() => handleTeamLeadSelect(lead)}
                      >
                        {lead.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="mb-2 flex gap-2 justify-between items-center flex-col relative">
              <input
                type="text"
                placeholder="Search employee name"
                onChange={(e) => setemployeeSearchTerm(e.target.value)}
                className="w-full p-1 border outline-none rounded-xl bg-[#f8f8f8]"
              />
              {searchResults.length > 0 && (
                <div className="w-full flex flex-col justify-start max-w-[300px] absolute top-10 rounded-md z-20">
                  {searchResults.map((employee) => (
                    <p
                      onClick={() => {
                        let employees = formData.teamMembers;
                        employees.push(employee);
                        setFormData((prevData) => ({
                          ...prevData,
                          teamMembers: employees,
                        }));
                        setemployeeSearchTerm("");
                        setSearchResults(() => []);
                      }}
                      className=" cursor-pointer"
                    >
                      {employee.name}
                    </p>
                  ))}
                </div>
              )}

              <div className="w-full -Z-10">
                {formData.teamMembers?.map((employee) => (
                  <div className="w-full p-1 border outline-none rounded-xl bg-[#f8f8f8]">
                    {employee.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="">
            <div className=" float-right">
              <button className="p-2 w-[120px] rounded-md bg-white border mr-2">
                Cancel
              </button>
              <button
                onClick={handleDoneClick}
                className="p-2 w-[120px] bg-[#625FFF] text-white rounded-md border "
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
