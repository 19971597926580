import React, { useState, useRef, useEffect } from "react";
import profileImage from "../../assets/profile-image.png";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";

const Profile = ({ setShowProfile, user, getUser }) => {
  const [profileData, setProfileData] = useState({
    password: "",
    tempImage: profileImage,
    image: null,
  });

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    if (e.target.name !== "image") {
      setProfileData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }));
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        tempImage: URL.createObjectURL(e.target.files[0]),
        image: e.target.files[0],
      }));
    }
  };

  const handleImageUploadClick = () => {
    fileInputRef.current.click();
  };

  const upDateImage = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/update`,
        { image: profileData.image, password: profileData.password },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        getUser();
        setShowProfile(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  return (
    <div className="bg-black/30 h-screen w-full fixed z-10 justify-center items-center">
      <div className="w-full h-screen relative">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="h-[400px] w-[400px] bg-white rounded-md flex flex-col justify-start items-center">
            <span className="font-semibold mt-4">My Profile</span>
            <div className="relative h-20 w-20 mt-8">
              <input
                type="file"
                accept="image/*"
                name="image"
                id="profile-image"
                className="hidden"
                ref={fileInputRef}
                onChange={handleChange}
              />
              <label htmlFor="profile-image">
                <img
                  src={
                    !user.profileImageData
                      ? profileData.tempImage
                      : `data:${user?.profileImageContentType};base64,${user?.profileImageData}`
                  }
                  alt="Profile"
                  className="h-20 w-20 rounded-full cursor-pointer"
                />
                <button
                  className="absolute bottom-0 right-0 flex justify-center items-center h-5 w-5 rounded-full bg-[#625FFF]"
                  onClick={handleImageUploadClick}
                >
                  <MdEdit className="h-4 w-4 text-white" />
                </button>
              </label>
            </div>
            <div className="w-full items-center mt-6 grid-col">
              <div className="text-[14px]">
                <span className="font-medium mr-4">Name:</span>
                <p className="py-2 px-2 outline-none">{user?.name}</p>
              </div>
              <div>
                <span className="font-medium mr-4">Username:</span>
                <p className="py-2 px-2 outline-none">{user.email}</p>
              </div>
              <div>
                <span className="font-medium mr-4">Password:</span>
                <input
                  value={profileData.password}
                  type="password"
                  name="password"
                  onChange={handleChange}
                  className="py-2 px-2 outline-none"
                />
              </div>
            </div>
            <button
              className="rounded-md border-none py-2 text-[14px] px-6 font-semibold bg-[#625FFF] text-white mt-6"
              onClick={() => {
                if (!profileData.image && !profileData.password)
                  setShowProfile(false);
                else upDateImage();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
