import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import logo from "../assets/Wesolutize_logo.png";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";

const LoginPage = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
 
  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      toast.error("Invalid email address");
      return;
    }
    if (
      !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/.test(formData.password)
    ) {
      toast.error(
        "Password must be at least 8 characters long and contain at least one digit, one uppercase letter, one lowercase letter, and one special character"
      );
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, {
        ...formData,
      });

      if (response.status === 200) {
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("role", response?.data?.role);
        localStorage.setItem("name", response?.data?.name);
        localStorage.setItem("userId", response?.data?.id);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  return (
    <div className="relative h-screen w-screen ">
      <div className="absolute inset-0 bg-login-bg bg-cover bg-center blur-md rounded-t-md"></div>
      <div className="absolute top-12 left-1/2 transform -translate-x-1/2 h-[85vh] w-[83vw] flex">
        <div className="w-1/2 bg-login-bg bg-cover bg-center rounded-l-3xl"></div>
        <div className=" bg-white bg-opacity-80 p-8 flex flex-col justify-center items-center w-1/2 rounded-r-3xl">
          <div className="w-4/5">
            <div className="flex justify-center items-center mb-4">
              <img src={logo} className="h-24" />
            </div>
            <h1 className=" mb-4 text-center text-3xl">Log In</h1>
            <h4 className="text-center font-light">
              Welcome to the CRM dashboard of MRR!
            </h4>
            <h4 className="text-center font-light mb-8">
              New User?{" "}
              <a href="#" className="text-blue-500 underline">
                Sign Up
              </a>
            </h4>
            <div className="flex flex-col items-center gap-4">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="email"
                className="w-full py-2 pl-4 rounded-full border border-gray-300 focus:outline-none"
                onChange={handleChange}
              />

              <div className="border border-gray-300 rounded-full font-[14px] focus:outline-none flex items-center w-full">
                <input
                  className="w-[90%] py-2 pl-4 rounded-full focus:outline-none"
                  type={visible ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                />
                {visible ? (
                  <AiFillEye onClick={() => setVisible(false)} />
                ) : (
                  <AiFillEyeInvisible onClick={() => setVisible(true)} />
                )}
              </div>
            </div>
            <div className="text-center flex justify-start py-3 mb-1">
              <a href="#" className="text-blue-500 underline">
                <h4>Forget Password?</h4>
              </a>
            </div>
            <div className="text-center">
              <button
                className="w-full p-2 rounded-full bg-indigo-600 text-white border border-indigo-700 hover:bg-indigo-700"
                onClick={handleSubmit}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
