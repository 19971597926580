import React, { useEffect, useState } from "react";
import { teamLead } from "../../Data/teamLeadData/data";
import CreateTaskForm from "../../components/TeamLead/CreateTaskForm";
import TaskList from "../../components/TeamLead/TaskList";
import axios from "axios";
import { toast } from "react-toastify";
const CreateTask = () => {
  const [projectStats, setProjectStats] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const getTeamLeadProjectTaskStats = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/teamlead-project-stats?teamLead=${localStorage.getItem(
          "userId"
        )}&searchTerm=${searchTerm}`
      );
      if (response.status === 200) {
        setProjectStats(response.data.leadProject);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getTeamLeadProjectTaskStats();
  }, [searchTerm]);

  return (
    <>
      {open && <TaskList setOpen={setOpen} selectedProject={selectedProject} />}
      <div
        className="mr-4 bg-white rounded-2xl py-4 px-2 relative top-4 h-[88vh] overflow-y-auto"
        style={{ scrollbarWidth: "none" }}
      >
        <div className="flex justify-between items-center h-[10%]">
          <h2 className="text-2xl font-medium text-[#625FFF] ">Project</h2>

          <div className="flex gap-2 ">
            <div>
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                type="search"
                placeholder="search here..."
                className="outline-none rounded-md border p-2"
              />
            </div>
            <div className="text-white py-2 w-[200px] px-4 bg-[#625FFF] rounded-md">
              Sort By
            </div>
          </div>
        </div>
        <div className="w-full h-[40vh] ">
          <div className="flex items-center justify-between bg-[#DEDEF4] rounded-t-md py-4 mt-2">
            <div className="w-[100%] text-center">
              <span className="text-[#625FFF]">Project List</span>
            </div>
          </div>

          <div
            className="bg-[#F8F8F8]  rounded-t-md text-center py-4 grid grid-cols-6 border-b border-[#D9D9D9]"
            style={{ gridTemplateColumns: "0.5fr 1fr 1fr 1fr 1fr 2fr " }}
          >
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Sr No</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Title</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Total Task</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Complete Task</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Details</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">
                Assigned Employee
              </span>
            </div>
          </div>

          <div
            className="bg-[#F8F8F8] h-[50%] overflow-y-auto rounded-t-md "
            style={{ scrollbarWidth: "none" }}
          >
            {projectStats?.map((project, index) => (
              <div
                className={`grid grid-cols-6 text-center mb-2 cursor-pointer py-1 border-b ${
                  selectedProject !== null && selectedProject === project?.id
                    ? "bg-[#8a8aff] text-white "
                    : "bg-[#F8F8F8] text-[#585353]"
                }`}
                onClick={() => {
                  setSelectedProject(project.id);
                  setEmployees(project.employees);
                }}
                style={{ gridTemplateColumns: "0.5fr 1fr 1fr 1fr 1fr 2fr " }}
              >
                <div className="flex justify-center items-center mb-2">
                  <span className="text-[14px]  font-semibold">
                    {index + 1}
                  </span>
                </div>
                <div className="flex justify-center items-center mb-2">
                  <span className="text-[14px]  font-semibold">
                    {project?.projectName}
                  </span>
                </div>
                <div className="flex justify-center items-center mb-2">
                  <span className="text-[14px]  font-semibold">
                    {project?.totalTasks}
                  </span>
                </div>

                <div className="flex justify-center items-center mb-2">
                  <span className="text-[14px]  font-semibold">
                    {project?.completedTasks}
                  </span>
                </div>

                <div className="flex justify-center items-center mb-2">
                  <span
                    className={`text-[14px] ${
                      selectedProject !== null &&
                      selectedProject === project?.id
                        ? "text-white"
                        : "text-[#625fff]"
                    }  underline font-semibold`}
                    onClick={() => setOpen(true)}
                  >
                    View Details
                  </span>
                </div>
                <div className="flex justify-center items-center mb-2">
                  {project?.employees?.map((employee) => (
                    <span
                      className="text-[14px] font-semibold"
                      key={employee?.id}
                    >
                      {employee?.name},
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <CreateTaskForm
          selectedProject={selectedProject}
          employees={employees}
          getTeamLeadProjectTaskStats={getTeamLeadProjectTaskStats}
        />
      </div>
    </>
  );
};

export default CreateTask;
