// import React,{useState} from "react";
// import Carousel from "react-multi-carousel";
// import { departments } from "../../Data/employeeData";
// import "react-multi-carousel/lib/styles.css";
// import AddEmployeeModal from "../../modal/admin/AddEmployeeModal";
// import { useNavigate } from "react-router-dom";
// import EditEmployeeModal from "../../modal/admin/EditEmployeeModal";
// import correct from "../../assets/correct.png"
// const responsive = {

//   xxxl: {
//     breakpoint: { max: 1700, min: 1500 },
//     items: 6,
//   },
//   xxl: {
//     breakpoint: { max: 1500, min: 1300 },
//     items: 5,
//   },
//   xl: {
//     breakpoint: { max: 1300, min: 1100 },
//     items: 5,
//   },
//   lg: {
//     breakpoint: { max: 1100, min: 900 },
//     items: 4,
//   },
//   md: {
//     breakpoint: { max: 900, min: 700 },
//     items: 3,
//   },
//   s: {
//     breakpoint: { max: 700, min: 500 },
//     items: 2,
//   },
//   xs: {
//     breakpoint: { max: 500, min: 400 },
//     items: 1,
//   },
// };

// const Employee = () => {

//   const [employee, setEmployee] = useState(departments[0].employee);
//   const [toggle,setToggle]=useState(false)
//   const [show,setShow]=useState(false)
//   const [selectedEmp,setSelectedEmp]=useState(null);
//   const addEmployeeModal=()=>{
//     setToggle(!toggle)
//   }
//   const viewEmployeeModal=(emp)=>{
//     setSelectedEmp(emp)
//     setShow(!show)
//   }

//   const navigate=useNavigate();

//   return(
//     <div className=" p-1 gap-4">
//       <div className=" h-[30vh] w-full bg-white">
//       <div className="bg-white rounded-2xl overflow-x-auto p-4">
//         <h2 className="text-[#625FFF]">Departments</h2>
//       <Carousel
//             responsive={responsive}
//             swipeable={true}
//             arrows={false}
//             className="mt-2"
//           >
//             {departments.map((department, index) => (
//               <div
//                 key={department.id}
//                 className="w-28 h-28 md:w-32 rounded-tl-xl flex flex-col items-center cursor-pointer"
//                 onClick={() => {
//                   // setCurrentDepartment(department.name);
//                   // setClients(departments[index].clients);
//                   setEmployee(departments[index].employee);
//                 }}
//               >
//                 <div
//                   className="h-full w-full bg-cover bg-center rounded-tl-xl"
//                   style={{ backgroundImage: `url(${department.imgUrl})` }}
//                 ></div>
//                 <p className="text-center mt-1 text-[14px]">
//                   {department.name}
//                 </p>
//               </div>
//             ))}
//           </Carousel>
//         </div>
//       </div>
//       <div className="mt-4 bg-white w-full h-[58vh]">
//           <div className="flex justify-between items-center mb-4 px-2">
//             <h2 className="text-xl font-semibold text-[#625FFF]">
//               Employee Details
//             </h2>
//             <div className="flex justify-end items-center gap-x-2">
//               <input
//                 // onChange={(e) => setSearchTerm(e.target.value)}
//                 placeholder="search project Employee"
//                 className=" text-[12px] py-4 px-2 outline-none  bg-[#f0f0f0]"
//               />
//             </div>
//           </div>

//           <div>
//           <div className="grid grid-cols-4 justify-between text-center items-center py-4 px-4 bg-[#DEDEF4] rounded-t-md">
//             <span className="text-[16px] text-[#625FFF] font-semibold">
//               EmployeeName
//             </span>
//             <span className="text-[16px] text-[#625FFF] font-semibold">
//               Position
//             </span>
//             <span className="text-[16px] text-[#625FFF] font-semibold">
//               Team Lead
//             </span>
//             <span className="text-[16px] text-[#625FFF] font-semibold">
//               Action
//             </span>
//           </div>

//           <div
//             className=" h-[32vh] overflow-y-auto"
//             style={{ scrollbarWidth: "none" }}
//           >
//             {employee.map((emp, index) => (
//               <div
//                 className="grid grid-cols-4 text-center justify-center items-center py-4 px-4 rounded-t-md cursor-pointer"
//                 // onClick={() => navigate("/view-project/woienwe")}
//               >
//                 <span className="text-[16px] font-semibold">
//                   {index + 1}. {emp.employeeName}
//                 </span>
//                 <span className="text-[16px] font-semibold">
//                   {emp.position}
//                 </span>
//                 <span className="text-[16px] font-semibold flex items-center justify-center">
//                   {emp.teamLead===true ?<img src={correct} className="w-[25px] " />:''}
//                 </span>
//                 <span className="text-[16px] font-semibold" onClick={()=>viewEmployeeModal(emp)}>
//                   View Details
//                 </span>

//               </div>
//             ))}
//           </div>

//           <div className="float-right px-2">
//             <button className="p-2 w-[150px] bg-white border rounded-md mr-2" >Cancel</button>
//             <button className="bg-[#625FFF] p-2 w-[150px] rounded-md text-white" onClick={addEmployeeModal}>Add Employee</button>
//           </div>
//         </div>

//         {
//            show && (
//             <EditEmployeeModal viewEmployeeModal={viewEmployeeModal} employee={selectedEmp} />
//           )
//         }
//         {
//           toggle && (
//             <AddEmployeeModal addEmployeeModal={addEmployeeModal} />
//           )
//         }

//       </div>
//     </div>
//   )
// };

// export default Employee;

// *********** New *****************
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AddEmployeeModal from "../../modal/admin/AddEmployeeModal";
import EditEmployeeModal from "../../modal/admin/EditEmployeeModal";
import correct from "../../assets/correct.png";
import { toast } from "react-toastify";
import axios from "axios";

const responsive = {
  xxxl: {
    breakpoint: { max: 1700, min: 1500 },
    items: 6,
  },
  xxl: {
    breakpoint: { max: 1500, min: 1300 },
    items: 5,
  },
  xl: {
    breakpoint: { max: 1300, min: 1100 },
    items: 5,
  },
  lg: {
    breakpoint: { max: 1100, min: 900 },
    items: 4,
  },
  md: {
    breakpoint: { max: 900, min: 700 },
    items: 3,
  },
  s: {
    breakpoint: { max: 700, min: 500 },
    items: 2,
  },
  xs: {
    breakpoint: { max: 500, min: 400 },
    items: 1,
  },
};

const Employee = () => {
  const [employees, setEmployees] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [department, setDepartment] = useState("");
  const [departments, setDepartments] = useState([]);

  const getEmployees = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/admin/employee`;

      if (search) {
        url += `?search=${encodeURIComponent(search)}`;
      }
      const response = await axios.post(url, { department });
      if (response.status === 200) {
        setEmployees(response.data.employees);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.responsive?.data?.message || "An error occured.");
    }
  };

  const getDepartments = async () => {
    try {
      const response = await axios.get(
        ` ${process.env.REACT_APP_API_URL}/admin/department`
      );
      if (response.status === 200) {
        setDepartments(response?.data?.departments);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getEmployees();
  }, [search, department]);

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <div className=" p-1 gap-4">
      <div className=" h-[30vh] w-full bg-white">
        <div className="bg-white rounded-2xl overflow-x-auto p-4">
          <h2 className="text-[#625FFF]">Departments</h2>
          <Carousel
            responsive={responsive}
            swipeable={true}
            arrows={false}
            className="mt-2"
          >
            {departments.map((department) => (
              <div
                key={department?.id}
                className="w-28 h-28 md:w-32 rounded-tl-xl flex flex-col items-center cursor-pointer"
                onClick={() => {
                  setDepartment(department?.name);
                }}
              >
                <div
                  className="h-full w-full bg-cover bg-center rounded-tl-xl"
                  style={{
                    backgroundImage: `url(data:${department?.imageContentType};base64,${department?.imageData})`,
                  }}
                ></div>
                <p className="text-center mt-1 text-[14px]">
                  {department?.name}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="mt-4 bg-white w-full h-[58vh]">
        <div className="flex justify-between items-center mb-4 px-2">
          <h2 className="text-xl font-semibold text-[#625FFF]">
            Employee Details
          </h2>
          <div className="flex justify-end items-center gap-x-2">
            <input
              placeholder="search Employee"
              className=" text-[12px] py-4 px-2 outline-none  bg-[#f0f0f0]"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="grid grid-cols-4 justify-between text-center items-center py-4 px-4 bg-[#DEDEF4] rounded-t-md">
            <span className="text-[16px] text-[#625FFF] font-semibold">
              EmployeeName
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Position
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Team Lead
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Action
            </span>
          </div>

          <div
            className=" h-[32vh] overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            {employees.map((employee, index) => (
              <div className="grid grid-cols-4 text-center justify-center items-center py-4 px-4 rounded-t-md cursor-pointer">
                <span className="text-[16px] font-semibold">
                  {index + 1}. {employee?.name}
                </span>
                <span className="text-[16px] font-semibold">
                  {employee?.position}
                </span>
                <span className="text-[16px] font-semibold flex items-center justify-center">
                  {employee?.role === "TEAMLEAD" ? (
                    <img src={correct} className="w-[25px] " />
                  ) : (    
                    ""
                  )}
                </span>
                <span
                  className="text-[16px] font-semibold"
                  onClick={() => {
                    localStorage.setItem("editEmployeeId", employee.id);
                    setShow(true);
                  }}
                >
                  View Details
                </span>
              </div>
            ))}
          </div>

          <div className="float-right px-2">
            <button className="p-2 w-[150px] bg-white border rounded-md mr-2">
              Cancel
            </button>
            <button
              className="bg-[#625FFF] p-2 w-[150px] rounded-md text-white"
              onClick={() => setToggle(true)}
            >
              Add Employee
            </button>
          </div>
        </div>

        {show && (
          <EditEmployeeModal
            setShow={setShow}
            getEmployees={getEmployees}
            departments={departments}
          />
        )}
        {toggle && (
          <AddEmployeeModal
            getEmployees={getEmployees}
            setToggle={setToggle}
            departments={departments}
          />
        )}
      </div>
    </div>
  );
};

export default Employee;
