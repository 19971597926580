import React, { useEffect, useState } from "react";
import profile from "../../assets/teamLead/leadprofile.png";
import TaskStatus from "../../components/TeamLead/TaskStatus";
import { BsArrowsAngleExpand } from "react-icons/bs";
import TaskStatusModal from "../../modal/teamLead/TaskStatusModal";
import { toast } from "react-toastify";
import axios from "axios";
import { json } from "react-router-dom";

const Team = () => {
  const [employees, setEmployees] = useState([]);
  const [progressTasks, setProgressTasks] = useState([]);
  const [delayedTasks, setDelayedTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [employee, setEmployee] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [project, setProject] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [taskSearchTerm, setTaskSearchTerm] = useState("");
  const [base64Image, setBase64Image] = useState("");

  console.log(project);

  const getTeamLeadTeam = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/team-lead-team?searchTerm=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setEmployees(response?.data.employees);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const getProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/employee-project/${employee?.id}`
      );
      if (response.status === 200) {
        setProjects(response.data.projectIds);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const getTasks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/employee-tasks-by-project?projectId=${project.id}&employeeId=${employee.id}&searchTerm=${taskSearchTerm}`
      );
      if (response.status === 200) {
        setCompletedTasks(response.data.completed);
        setDelayedTasks(response.data.delayed);
        setProgressTasks(response.data.inProgress);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const bufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    getTeamLeadTeam();
  }, [searchTerm]);

  useEffect(() => {
    if (employee.id) getProjects();
    const base64String = bufferToBase64(employee.profileImageData?.data);
    setBase64Image(base64String);
  }, [employee]);

  useEffect(() => {
    if (project) getTasks();
  }, [taskSearchTerm, project]);

  return (
    <div
      className="mr-4 bg-white rounded-2xl py-4 px-2 relative top-4 h-[88vh] overflow-y-auto"
      style={{ scrollbarWidth: "none" }}
    >
      <div className="flex justify-between items-center h-[10%] ">
        <h2 className="text-2xl font-medium text-[#625FFF] ">Project</h2>
        <div className="flex gap-2 ">
          <div>
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              type="search"
              placeholder="search here..."
              className="outline-none rounded-md border p-2"
            />
          </div>
          <div>
            <p className="bg-[#625FFF] p-2 text-white rounded-md w-[150px]">
              "Department"
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex gap-4 h-[60%]">
        <div className="w-[60%] ">
          <h1 className="bg-[#DEDEF3] text-[#6360FF] rounded-tl-xl rounded-tr-xl text-center p-2">
            Employees
          </h1>
          <div
            className="grid grid-cols-3 text-center h-[40px] text-[14px] bg-[#F8F8F8] p-1 text-[#585353]"
            style={{ gridTemplateColumns: "0.5fr 1fr 1fr" }}
          >
            <div>
              <span>Sr No</span>
            </div>

            <div>
              <span>Employee Name</span>
            </div>

            {/* <div>
              <span>Working on</span>
            </div> */}

            <div>
              <span>Designation</span>
            </div>
          </div>
          <hr />
          <div
            className=" bg-[#F8F8F8] rounded-bl-xl rounded-br-xl overflow-y-auto h-[75%]"
            style={{ scrollbarWidth: "none" }}
          >
            {employees?.map((employee, index) => (
              <div
                key={index}
                className="grid grid-cols-3 h-[40px] text-center  p-1 cursor-pointer"
                style={{ gridTemplateColumns: "0.5fr 1fr 1fr" }}
                onClick={() => {
                  setProject(null);
                  setEmployee(employee);
                  getProjects();
                }}
              >
                <div className="flex justify-center items-center">
                  {index + 1}
                </div>
                <div className="flex justify-center items-center">
                  {employee?.name}
                </div>
                {/* <div className="flex justify-center items-center">
                  {employee.working_on}
                </div> */}
                <div className="flex justify-center items-center">
                  {employee?.position}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-[40%] bg-[#F8F8F8] flex flex-col h-[100%] justify-center items-center rounded-xl">
          <h1 className="mt-4 text-[#6360FF]">Employee Details</h1>
          {employee?.name && (
            <div className="border w-[100px]  h-[100px] rounded-full mt-2 mb-2">
              <img
                src={`data:${employee.profileImageContentType};base64,${base64Image}`}
                alt="Profile"
                className="h-[100px] rounded-full"
              />
            </div>
          )}
          <div className="w-full flex flex-col justify-center items-center">
            {employee?.name && (
              <>
                <div className="flex w-[80%] justify-center mb-2">
                  <label className="w-[40%]">Name :</label>
                  <p className="w-[60%]">{employee?.name}</p>
                </div>

                <div className="flex w-[80%] justify-center mb-2">
                  <label className="w-[40%]">Designation :</label>
                  <p className="w-[60%]">{employee?.position}</p>
                </div>

                <div className="flex w-[80%] justify-center mb-2">
                  <label className="w-[40%]">Username :</label>
                  <p className="w-[60%]">{employee?.email}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center h-[10%] mt-4 mb-4">
        <h2 className="text-2xl font-medium text-[#625FFF] ">
          {project?.name}
        </h2>
        <div className="flex gap-2 ">
          <div>
            <input
              onChange={(e) => setTaskSearchTerm(e.target.value)}
              type="search"
              placeholder="search here..."
              className="outline-none rounded-md border p-2"
            />
          </div>
          <div>
            <select
              className="bg-[#625FFF] p-2 text-white rounded-md outline-none w-[150px]"
              onChange={(e) => {
                setProject(JSON.parse(e.target.value));
              }}
              value={JSON.stringify(project)}
            >
              <option value="" disabled selected>
                Select
              </option>
              {employee?.id &&
                projects.map((project, index) => (
                  <option key={index} value={JSON.stringify(project)}>
                    {project?.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div className="h-auto bg-[#F8F8F8] p-4 rounded-xl">
        <div className="grid grid-cols-3 gap-4">
          {progressTasks.length > 0 && (
            <TaskStatus task={progressTasks[0]} status="In Progress" />
          )}
          {completedTasks.length > 0 && (
            <TaskStatus task={completedTasks[0]} status="Completed" />
          )}
          {delayedTasks.length > 0 && (
            <TaskStatus task={delayedTasks[0]} status="delayed" />
          )}
        </div>

        <div className="p-2 h-auto flex justify-end">
          <div className="cursor-pointer" onClick={() => setModalOpen(true)}>
            <BsArrowsAngleExpand />
            <p>zoom</p>
          </div>
        </div>
      </div>

      {modalOpen && (
        <TaskStatusModal
          setModalOpen={setModalOpen}
          completedTasks={completedTasks}
          delayedTasks={delayedTasks}
          progressTasks={progressTasks}
          setTaskSearchTerm={setTaskSearchTerm}
          setProject={setProject}
          projects={projects}
          project={project}
        />
      )}
    </div>
  );
};
export default Team;
