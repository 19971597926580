// import { MdEdit } from "react-icons/md";

// const ViewProject = () => {
//   return (
//     <div className="mr-4 bg-white rounded-2xl py-4 px-2">
//       <div className="flex justify-between items-center">
//         <h2 className="text-2xl font-medium text-[#625FFF]">Project</h2>
//         <div className="text-white py-2 px-4 bg-[#625FFF] rounded-md">
//           IT - Full Stack
//         </div>
//       </div>
//       <div className="w-full">
//         <div className="flex items-center justify-center bg-[#DEDEF4] rounded-t-md py-4 mt-2">
//           <span className="text-[#625FFF]">Client Info</span>
//           <MdEdit className="h-4 w-4 text-[#585353] ml-2" />
//         </div>

//         <div className="bg-[#F8F8F8] rounded-t-md py-4 grid grid-cols-3 border-b border-[#D9D9D9]">
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">Name</span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">Contact No.</span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">Mail ID</span>
//           </div>
//         </div>
//         <div className="bg-[#F8F8F8] rounded-t-md py-4 grid grid-cols-3">
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               Pankaj Gawali
//             </span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               +91 9665883207
//             </span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               myresearchroom.com
//             </span>
//           </div>
//         </div>
//       </div>
//       <div>
//         <div className="flex items-center justify-center bg-[#DEDEF4] rounded-t-md py-4 mt-2">
//           <span className="text-[#625FFF]">Handled By</span>
//           <MdEdit className="h-4 w-4 text-[#585353] ml-2" />
//         </div>

//         <div className="bg-[#F8F8F8] rounded-t-md py-4 grid grid-cols-3 border-b border-[#D9D9D9]">
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">Lead</span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">Employee</span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">Employee 2</span>
//           </div>
//         </div>
//         <div className="bg-[#F8F8F8] rounded-t-md py-4 grid grid-cols-3">
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               Sohail H.
//             </span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               Rohit S.
//             </span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               Aditi S.
//             </span>
//           </div>
//         </div>
//       </div>
//       <div>
//         <div className="flex items-center justify-center bg-[#DEDEF4] rounded-t-md py-4 mt-2">
//           <span className="text-[#625FFF]">Project Info</span>
//           <MdEdit className="h-4 w-4 text-[#585353] ml-2" />
//         </div>

//         <div className="bg-[#F8F8F8] rounded-t-md py-4 grid grid-cols-3 border-b border-[#D9D9D9]">
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">Start Date</span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">End Date</span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353]">Requirements</span>
//           </div>
//         </div>
//         <div className="bg-[#F8F8F8] rounded-t-md py-4 grid grid-cols-3">
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               22/12/23
//             </span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               26/02/24
//             </span>
//           </div>
//           <div className="flex justify-center items-center">
//             <span className="text-[16px] text-[#585353] font-semibold">
//               Details
//             </span>
//           </div>
//         </div>
//       </div>
//       <div className="flex justify-end items-center w-full mt-4">
//         <button className="text-[#585353] text-[18px] py-2 px-8 border rounded-xl border-[#E7E1E1] mr-2">
//           Cancel
//         </button>
//         <button className="text-white text-[18px] py-2 px-8 border font-light rounded-xl bg-[#625FFF]">
//           Done
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ViewProject;

// ************* New *******************

import axios from "axios";
import { useEffect, useState } from "react";
import { MdEdit, MdAdd } from "react-icons/md";
import Carousel from "react-multi-carousel";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import RequirementsModal from "../../modal/admin/RequirementsModal";

const responsive = {
  xxxl: {
    breakpoint: { max: 1700, min: 1500 },
    items: 3,
  },
  xxl: {
    breakpoint: { max: 1500, min: 1300 },
    items: 3,
  },
  xl: {
    breakpoint: { max: 1300, min: 1100 },
    items: 3,
  },
  lg: {
    breakpoint: { max: 1100, min: 900 },
    items: 3,
  },
  md: {
    breakpoint: { max: 900, min: 700 },
    items: 3,
  },
  s: {
    breakpoint: { max: 700, min: 500 },
    items: 2,
  },
  xs: {
    breakpoint: { max: 500, min: 400 },
    items: 1,
  },
};

const ViewProject = () => {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const [teamLeads, setTeamLeads] = useState([]);
  const [nonTeamLeads, setNonTeamLeads] = useState([]);
  const [renderKey, setRenderKey] = useState(0);

  const getProject = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/project/${id}`
      );
      if (response.status === 200) {
        setProject(response.data.project);
        setEditedProject(response.data.project);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || "An error occured");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProject({ ...editedProject, [name]: value });
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/project/edit-project/${id}`,
        editedProject,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setProject(editedProject);
        setIsEditing(false);
        toast.success("Project updated successfully!");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message ||
          "An error occurred while updating the project."
      );
    }
  };

  const getTeamLeads = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/admin/team-leads`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setTeamLeads(response.data.teamLeads);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.responsive?.data?.message || "An error occured.");
    }
  };

  const getNonTeamLeads = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/admin/non-team-leads`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setNonTeamLeads(response?.data?.nonTeamLeads);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.responsive?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getTeamLeads();
    getNonTeamLeads();
  }, []);

  useEffect(() => {
    getProject();
  }, [id]);

  return (
    <>
      {showModal && (
        <RequirementsModal
          projectName={project.projectName}
          requirements={project.requirements}
          requirementsPdfContentType={project.requirementsPdfContentType}
          requirementsPdfData={project.requirementsPdfData}
          getProject={getProject}
          setShowModal={setShowModal}
        />
      )}
      <div className="mr-4 bg-white rounded-2xl py-3 px-2">
        <div className="flex justify-between items-center">
          {isEditing ? (
            <input
              type="text"
              name="projectName"
              value={editedProject.projectName}
              onChange={handleInputChange}
              className="text-2xl font-medium text-[#625FFF]"
            />
          ) : (
            <h2 className="text-2xl font-medium text-[#625FFF]">
              {project?.projectName}
            </h2>
          )}
          <div className="text-white py-2 px-4 bg-[#625FFF] rounded-md">
            {project?.departmentName}
          </div>
        </div>
        <div className="w-full">
          <div className="flex items-center justify-center bg-[#DEDEF4] rounded-t-md py-3 mt-2">
            <span className="text-[#625FFF]">Client Info</span>
          </div>
          <div className="bg-[#F8F8F8] rounded-t-md py-3 grid grid-cols-3 border-b border-[#D9D9D9]">
            <div className="flex justify-center items-center">
              <span className="text-[16px] text-[#585353]">Name</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[16px] text-[#585353]">Contact No.</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[16px] text-[#585353]">Mail ID</span>
            </div>
          </div>
          <div className="bg-[#F8F8F8] rounded-t-md py-3 grid grid-cols-3">
            <div className="flex justify-center items-center">
              {isEditing ? (
                <input
                  type="text"
                  name="clientName"
                  value={editedProject.clientName}
                  onChange={handleInputChange}
                  className="text-[16px] text-[#585353] font-semibold"
                />
              ) : (
                <span className="text-[16px] text-[#585353] font-semibold">
                  {project?.clientName}
                </span>
              )}
            </div>
            <div className="flex justify-center items-center">
              {isEditing ? (
                <input
                  type="text"
                  name="clientNumber"
                  value={editedProject.clientNumber}
                  onChange={handleInputChange}
                  className="text-[16px] text-[#585353] font-semibold"
                />
              ) : (
                <span className="text-[16px] text-[#585353] font-semibold">
                  {project?.clientNumber}
                </span>
              )}
            </div>
            <div className="flex justify-center items-center">
              {isEditing ? (
                <input
                  type="text"
                  name="clientMail"
                  value={editedProject.clientMail}
                  onChange={handleInputChange}
                  className="text-[16px] text-[#585353] font-semibold"
                />
              ) : (
                <span className="text-[16px] text-[#585353] font-semibold">
                  {project?.clientMail}
                </span>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-center bg-[#DEDEF4] rounded-t-md py-3 mt-2">
            <span className="text-[#625FFF]">Handled By</span>
          </div>
          {!isEditing
            ? project?.employees?.length > 0 && (
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  arrows={true}
                >
                  <div
                    className="flex items-center flex-col w-full"
                    key={project?.teamLead.name}
                  >
                    <div className="bg-[#F8F8F8] rounded-t-md py-3 border-b w-full flex justify-center items-center">
                      <span className="text-[16px] text-[#585353]">Lead</span>
                    </div>
                    <div className="bg-[#F8F8F8] rounded-t-md py-3 w-full flex justify-center items-center">
                      <span className="text-[16px] text-[#585353] font-semibold">
                        {project?.teamLead?.name}
                      </span>
                    </div>
                  </div>
                  {project?.employees?.map((employee, index) => (
                    <div
                      className="flex items-center flex-col w-full"
                      key={employee?.name}
                    >
                      <div className="bg-[#F8F8F8] rounded-t-md py-3 border-b w-full flex justify-center items-center">
                        <span className="text-[16px] text-[#585353]">
                          {"Employee " + (index + 2)}
                        </span>
                      </div>
                      <div className="bg-[#F8F8F8] rounded-t-md py-3 w-full flex justify-center items-center">
                        <span className="text-[16px] text-[#585353] font-semibold">
                          {employee?.name}
                        </span>
                      </div>
                    </div>
                  ))}
                </Carousel>
              )
            : editedProject?.employees?.length > 0 && (
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  arrows={true}
                  key={renderKey}
                >
                  <div
                    className="flex items-center flex-col w-full"
                    key={project?.teamLead.name}
                  >
                    <div className="bg-[#F8F8F8] rounded-t-md py-3 border-b w-full flex justify-center items-center">
                      <span className="text-[16px] text-[#585353]">Lead</span>
                    </div>
                    <div className="bg-[#F8F8F8] rounded-t-md py-3 w-full flex justify-center items-center">
                      <select
                        value={JSON.stringify(editedProject.teamLead)}
                        onChange={(e) =>
                          setEditedProject((prevData) => ({
                            ...prevData,
                            teamLead: JSON.parse(e.target.value),
                          }))
                        }
                      >
                        <option value="" disabled selected>Select</option>
                        {teamLeads.map((lead) => (
                          <option
                            value={JSON.stringify({
                              id: lead.user_id,
                              name: lead.name,
                            })}
                            className="text-[16px] text-[#585353] font-semibold"
                          >
                            {lead?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {editedProject?.employees?.map((employee, index) => (
                    <div
                      className="flex items-center flex-col w-full"
                      key={employee?.name}
                    >
                      <div className="bg-[#F8F8F8] rounded-t-md py-3 border-b w-full flex justify-center items-center">
                        <span className="text-[16px] text-[#585353]">
                          {"Employee " + (index + 2)}
                        </span>
                      </div>
                      <div className="bg-[#F8F8F8] rounded-t-md py-3 w-full flex justify-center items-center">
                        <select
                          value={JSON.stringify({
                            lead: editedProject.employees[index] || {
                              id: "",
                              name: "",
                            }, // Set default value if not available
                            index,
                          })}
                          onChange={(e) => {
                            const addIndex = JSON.parse(e.target.value).index;
                            let employees = editedProject.employees;
                            employees[addIndex] = JSON.parse(
                              e.target.value
                            ).lead;
                            setEditedProject((prevData) => ({
                              ...prevData,
                              employees,
                            }));
                          }}
                        >
                          <option value="" disabled selected>Select</option>
                          {nonTeamLeads.map((lead) => (
                            <option
                              value={JSON.stringify({
                                lead: { id: lead.user_id, name: lead.name },
                                index,
                              })}
                              className="text-[16px] text-[#585353] font-semibold"
                              // selected={lead.name === employee.name}
                            >
                              {lead?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ))}
                  <div className="flex items-center flex-col w-full">
                    <div className="bg-[#F8F8F8] rounded-t-md py-3 border-b w-full flex justify-center items-center">
                      <span className="text-[16px] text-[#585353]">
                        {"Employee " + (editedProject.employees.length + 2)}
                      </span>
                    </div>
                    <div className="bg-[#F8F8F8] rounded-t-md py-3 w-full flex justify-center items-center">
                      <button
                        className="text-[16px] text-[#585353] font-semibold"
                        onClick={() => {
                          setEditedProject((prevData) => ({
                            ...prevData,
                            employees: [
                              ...prevData.employees,
                              { id: "", name: "" },
                            ],
                          }));
                          setRenderKey(renderKey + 1);
                        }}
                      >
                        <MdAdd className="h-6 w-6 text-gray-400" />
                      </button>
                    </div>
                  </div>
                </Carousel>
              )}
        </div>
        <div>
          <div className="flex items-center justify-center bg-[#DEDEF4] rounded-t-md py-3 mt-2">
            <span className="text-[#625FFF]">Project Info</span>
          </div>
          <div className="bg-[#F8F8F8] rounded-t-md py-3 grid grid-cols-3 border-b border-[#D9D9D9]">
            <div className="flex justify-center items-center">
              <span className="text-[16px] text-[#585353]">Start Date</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[16px] text-[#585353]">End Date</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[16px] text-[#585353]">Requirements</span>
            </div>
          </div>
          <div className="bg-[#F8F8F8] rounded-t-md py-3 grid grid-cols-3">
            <div className="flex justify-center items-center">
              {isEditing ? (
                <input
                  type="date"
                  name="startDate"
                  value={editedProject.startDate?.slice(0, 10)}
                  onChange={handleInputChange}
                  className="text-[16px] text-[#585353] font-semibold"
                />
              ) : (
                <span className="text-[16px] text-[#585353] font-semibold">
                  {project?.startDate?.slice(0, 10)}
                </span>
              )}
            </div>
            <div className="flex justify-center items-center">
              {isEditing ? (
                <input
                  type="date"
                  name="endDate"
                  value={editedProject.endDate?.slice(0, 10)}
                  onChange={handleInputChange}
                  className="text-[16px] text-[#585353] font-semibold"
                />
              ) : (
                <span className="text-[16px] text-[#585353] font-semibold">
                  {project?.endDate?.slice(0, 10)}
                </span>
              )}
            </div>
            <button
              className="flex justify-center items-center"
              disabled={isEditing}
            >
              <span
                className={`text-[16px] text-[#585353] font-semibold cursor-pointer ${
                  isEditing && "text-gray-300"
                }`}
                onClick={() => setShowModal(true)}
              >
                Details
              </span>
            </button>
          </div>
        </div>
        <div className="flex justify-end items-center w-full mt-4">
          {isEditing ? (
            <>
              <button
                onClick={() => {
                  setEditedProject(project);
                  setIsEditing(false);
                }}
                className="text-[#585353] py-2 px-8 border rounded-md border-[#E7E1E1] mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveClick}
                className="text-white py-2 px-8 border font-light rounded-md bg-[#625FFF] flex justify-center items-center"
              >
                Save
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="text-white py-2 px-8 border font-light rounded-md bg-[#625FFF] flex justify-center items-center"
            >
              <MdEdit className="text-white h-4 w-4 mr-2" /> Edit Details
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewProject;
