import React, { useState, useEffect } from "react";
import link from "../../assets/link.png";
import { employee } from "../../Data/teamLeadData/data";
import { toast } from "react-toastify";
import axios from "axios";
const CreateTaskForm = ({
  selectedProject,
  employees,
  getTeamLeadProjectTaskStats,
}) => {
  const [formData, setFormData] = useState({
    taskTitle: "",
    taskNo: "",
    description: "",
    startDate: "",
    endDate: "",
    pdf: "",
    requirements: "",
    url: "",
    assignedEmployees: [],
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [showList, setShowList] = useState([]);

  const [filteredEmployees, setFilteredEmployees] = useState([]);

  React.useEffect(() => {
    if (searchQuery) {
      const filtered = employees.filter((emp) =>
        emp.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, assignedEmployees: selectedEmployees }));
  }, [selectedEmployees]);

  // Handle selection of an employee
  const handleSelectEmployee = (employee) => {
    if (!selectedEmployees.includes(employee)) {
      setSelectedEmployees([...selectedEmployees, employee]);
      setSearchQuery("");
    }
  };

  // Handle removal of an employee from selected list
  const handleRemoveEmployee = (employeeToRemove) => {
    const updatedEmployees = selectedEmployees.filter(
      (emp) => emp.id !== employeeToRemove
    );
    setSelectedEmployees(updatedEmployees);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      pdf: file,
    }));
  };

  const handleSubmit = async () => {
    const updatedFromData = {};
    updatedFromData["name"] = formData.taskTitle;
    updatedFromData["number"] = formData.taskNo;
    updatedFromData["startDate"] = formData.startDate;
    updatedFromData["endDate"] = formData.endDate;
    updatedFromData["employees"] = formData.assignedEmployees;
    updatedFromData["employees"] = formData.assignedEmployees;
    updatedFromData["note"] = formData.requirements;
    updatedFromData["requirementsPdf"] = formData.pdf;
    updatedFromData["url"] = formData.url;
    updatedFromData["description"] = formData.description;
    updatedFromData["description"] = formData.description;
    updatedFromData["projectId"] = selectedProject;
    updatedFromData["teamLead"] = {
      id: localStorage.getItem("userId"),
      name: localStorage.getItem("name"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/lead/task/create-task`,
        updatedFromData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 201) {
        getTeamLeadProjectTaskStats();
        toast.success(response?.data?.message || "Task created successfully");
        setFormData({
          taskTitle: "",
          taskNo: "",
          description: "",
          startDate: "",
          endDate: "",
          pdf: "",
          requirements: "",
          url: "",
          assignedEmployees: [],
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  return (
    <>
      {!selectedProject && (
        <p className="w-full text-center text-[12px] my-1 text-red-600">
          *Select the project
        </p>
      )}
      <div
        className="w-full h-auto mb-2 mt-2"
        style={{ scrollbarWidth: "none" }}
      >
        <h1 className="text-[#625FFF] mt-2 mb-2 font-semibold text-xl">
          Create Task For {selectedProject}{" "}
        </h1>

        <div className="w-full flex  h-full bg-[#F8F8F8] ">
          {/* ******* LEft ********* */}
          <div className="w-[50%] h-full p-4">
            <div className="w-full flex mb-2">
              <label className="w-[30%]">Task Title:</label>
              <input
                placeholder="Enter Task"
                name="taskTitle"
                value={formData.taskTitle}
                onChange={handleChange}
                className="outline-none border w-[60%] p-1 rounded-md"
              />
            </div>

            <div className="w-full flex mb-2">
              <label className="w-[30%]">Task No:</label>
              <input
                type="number"
                placeholder="Select No"
                name="taskNo"
                value={formData.taskNo}
                onChange={handleChange}
                className="outline-none border w-[60%] p-1 rounded-md"
              />
            </div>

            <div className="w-full flex mb-2">
              <label className="w-[30%]">Description:</label>
              <textarea
                placeholder="Enter Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                style={{ resize: "none" }}
                className="rounded-md outline-none border w-[60%] p-1"
              />
            </div>

            <div className="w-full flex mb-2">
              <label className="w-[30%]">Start Date:</label>
              <input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                className="outline-none border w-[60%] p-1 rounded-md"
              />
            </div>

            <div className="w-full flex">
              <label className="w-[30%]">End Date:</label>
              <input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                className="outline-none border w-[60%] p-1 rounded-md"
              />
            </div>
          </div>

          {/* ******* Right *********** */}
          <div className="w-[50%] border-l-2 h-auto p-4">
            <div className="w-full flex">
              <label className="w-[40%]">Assigned Employee:</label>
              <div className="w-[60%]">
                <input
                  type="search"
                  placeholder="Search Employee"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setShowList(true);
                  }}
                  className="outline-none border w-full p-1 rounded-md"
                />

                {searchQuery && (
                  <div className="flex flex-col mt-4 h-[100px] overflow-y-auto">
                    {filteredEmployees.map((employee) => (
                      <div
                        key={employee.id}
                        className="cursor-pointer py-2"
                        onClick={() => handleSelectEmployee(employee)}
                      >
                        {employee.name}
                      </div>
                    ))}
                  </div>
                )}

                {/* Selected employees */}
                <div className="mt-4">
                  {selectedEmployees.map((employee) => (
                    <div
                      key={employee.id}
                      className="flex items-center justify-between bg-gray-200 p-2 rounded-md mt-2"
                    >
                      <span>{employee.name}</span>
                      <button onClick={() => handleRemoveEmployee(employee.id)}>
                        &#9747;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full flex mt-2">
              <label className="w-[40%]">Requirements:</label>
              <textarea
                name="requirements"
                value={formData.requirements}
                onChange={handleChange}
                placeholder="Write Notes Here"
                className="outline-none border w-[60%] p-1 rounded-md"
              />
            </div>

            <div className="float-right mt-2 mb-4">
              <label htmlFor="upload" className="cursor-pointer">
                <span className="text-white flex gap-1 rounded-xl px-4 py-2 bg-[#625FFF] ">
                  <img src={link} className="w-[15px] h-[20px]" />
                  Attach Pdf
                </span>
              </label>
              <input
                type="file"
                id="upload"
                onChange={handleFileChange}
                hidden
              />
              {formData.pdf && <p>{formData.pdf.name}</p>}
            </div>

            <div className="w-full flex">
              <label className="w-[40%]">File URL:</label>
              <input
                placeholder="Enter File URL Here"
                name="url"
                value={formData.url}
                onChange={handleChange}
                className="outline-none border w-[60%] p-1 rounded-md"
              />
            </div>

            <div className="w-full flex gap-2 justify-end mt-4">
              <button className="border px-2 py-1 w-[120px] rounded-md">
                Cancel
              </button>
              <button
                className="bg-[#625FFF] text-white w-[120px] py-1 px-2 rounded-md"
                onClick={handleSubmit}
              >
                Create Task
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTaskForm;
