// import { FaRegSquare } from "react-icons/fa6";
// import { TbLayoutDashboardFilled } from "react-icons/tb";
// import { IoIosAddCircle } from "react-icons/io";
// import { RiTeamFill } from "react-icons/ri";
// import { RiMessage3Fill } from "react-icons/ri";

// import profileImage from "../../assets/profile-image.png";
// import { Link, useLocation } from "react-router-dom";
// import Profile from "../../modal/admin/Profile";
// import { useState } from "react";

// const Sidebar = () => {
//   const location = useLocation();
//   const pathname = location.pathname;

//   const [showProfile, setShowProfile] = useState(false);

//   return (
//     <>
//       {showProfile && <Profile setShowProfile={setShowProfile} />}
//       <div className="absolute top-0 left-0 h-screen w-1/5 flex justify-center items-center">
//         <div className="bg-white mt-8 w-[90%] md:w-[88%] h-[86vh] flex flex-col items-center rounded-2xl">
//           <div className="flex pt-7 items-center">
//             <FaRegSquare className="h-6 w-8 font-extrabold text-[#625FFF] mr-2" />{" "}
//             <span className="font-bold tracking-widest">MRR</span>
//           </div>
//           <div className="flex flex-col items-center mt-12">
//             <img
//               src={profileImage}
//               alt="profile image"
//               className=" h-16 w-16 rounded-full"
//             />
//             <button
//               className="font-bold text-[12px] text-white bg-[#625FFF] mt-3 px-4 py-2 rounded-md"
//               onClick={() => setShowProfile(true)}
//             >
//               My Profile
//             </button>
//           </div>
//           <div className="mt-12 flex flex-col justify-start gap-y-3">
//             <Link
//               to="/"
//               className={`flex items-center border-l-4 rounded-sm pl-4 ${
//                 pathname === "/" ? "border-[#625FFF]" : "border-white"
//               }`}
//             >
//               <TbLayoutDashboardFilled
//                 className={`mr-2 h-5 w-5 ${
//                   pathname === "/" ? "text-[#625FFF]" : "border-white"
//                 }`}
//               />{" "}
//               <span className={`${pathname === "/" ? "text-[#625FFF]" : ""}`}>
//                 Dashboard
//               </span>
//             </Link>
//             <Link
//               to="/add-project"
//               className={`flex items-center border-l-4 rounded-sm pl-4 ${
//                 pathname === "/add-project"
//                   ? "border-[#625FFF]"
//                   : "border-white"
//               }`}
//             >
//               <IoIosAddCircle
//                 className={`mr-2 h-5 w-5 ${
//                   pathname === "/add-project"
//                     ? "text-[#625FFF]"
//                     : "border-white"
//                 }`}
//               />{" "}
//               <span
//                 className={`${
//                   pathname === "/add-project" ? "text-[#625FFF]" : ""
//                 }`}
//               >
//                 Add Projects
//               </span>
//             </Link>
//             <Link
//               to="/add-department"
//               className={`flex items-center border-l-4 $ border-[#625FFF] rounded-sm pl-4 ${
//                 pathname === "/add-department"
//                   ? "border-[#625FFF]"
//                   : "border-white"
//               }`}
//             >
//               <RiTeamFill
//                 className={`mr-2 h-5 w-5 ${
//                   pathname === "/add-department"
//                     ? "text-[#625FFF]"
//                     : "border-white"
//                 }`}
//               />{" "}
//               <span
//                 className={`${
//                   pathname === "/add-department" ? "text-[#625FFF]" : ""
//                 }`}
//               >
//                 Add Department
//               </span>
//             </Link>
//             <Link
//               to="/employees"
//               className={`flex items-center border-l-4 $ border-[#625FFF] rounded-sm pl-4 ${
//                 pathname === "/employees" ? "border-[#625FFF]" : "border-white"
//               }`}
//             >
//               <RiTeamFill
//                 className={`mr-2 h-5 w-5 ${
//                   pathname === "/employees" ? "text-[#625FFF]" : "border-white"
//                 }`}
//               />{" "}
//               <span
//                 className={`${
//                   pathname === "/employees" ? "text-[#625FFF]" : ""
//                 }`}
//               >
//                 Employee
//               </span>
//             </Link>
//             <Link
//               to="/accounts"
//               className={`flex items-center border-l-4 $ border-[#625FFF] rounded-sm pl-4 ${
//                 pathname === "/accounts" ? "border-[#625FFF]" : "border-white"
//               }`}
//             >
//               <RiMessage3Fill
//                 className={`mr-2 h-5 w-5 ${
//                   pathname === "/accounts" ? "text-[#625FFF]" : "border-white"
//                 }`}
//               />{" "}
//               <span
//                 className={`${
//                   pathname === "/accounts" ? "text-[#625FFF]" : ""
//                 }`}
//               >
//                 Accounts
//               </span>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Sidebar;

// ************* New **************

import { FaRegSquare } from "react-icons/fa6";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { IoIosAddCircle } from "react-icons/io";
import { RiTeamFill } from "react-icons/ri";
import { RiMessage3Fill } from "react-icons/ri";
import profileImage from "../../assets/profile-image.png";
import { Link, useLocation } from "react-router-dom";
import Profile from "../../modal/admin/Profile";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [showProfile, setShowProfile] = useState(false);
  const [user, setUser] = useState({});

  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/get-user`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {showProfile && (
        <Profile
          setShowProfile={setShowProfile}
          user={user}
          getUser={getUser}
        />
      )}
      <div className="absolute top-0 left-0 h-screen w-1/5 flex justify-center items-center">
        <div className="bg-white mt-8 w-[90%] md:w-[88%] h-[86vh] flex flex-col items-center rounded-2xl">
          <div className="flex pt-7 items-center">
            <FaRegSquare className="h-6 w-8 font-extrabold text-[#625FFF] mr-2" />{" "}
            <span className="font-bold tracking-widest">MRR</span>
          </div>
          <div className="flex flex-col items-center mt-12">
            <img
              src={
                !user.profileImageData
                  ? profileImage
                  : `data:${user?.profileImageContentType};base64,${user?.profileImageData}`
              }
              alt="profile image"
              className=" h-16 w-16 rounded-full"
            />
            <button
              className="font-bold text-[12px] text-white bg-[#625FFF] mt-3 px-4 py-2 rounded-md"
              onClick={() => setShowProfile(true)}
            >
              My Profile
            </button>
          </div>
          <div className="mt-12 flex flex-col justify-start gap-y-3">
            <Link
              to="/"
              className={`flex items-center border-l-4 rounded-sm pl-4 ${
                pathname === "/" ? "border-[#625FFF]" : "border-white"
              }`}
            >
              <TbLayoutDashboardFilled
                className={`mr-2 h-5 w-5 ${
                  pathname === "/" ? "text-[#625FFF]" : "border-white"
                }`}
              />{" "}
              <span className={`${pathname === "/" ? "text-[#625FFF]" : ""}`}>
                Dashboard
              </span>
            </Link>
            <Link
              to="/add-project"
              className={`flex items-center border-l-4 rounded-sm pl-4 ${
                pathname === "/add-project"
                  ? "border-[#625FFF]"
                  : "border-white"
              }`}
            >
              <IoIosAddCircle
                className={`mr-2 h-5 w-5 ${
                  pathname === "/add-project"
                    ? "text-[#625FFF]"
                    : "border-white"
                }`}
              />{" "}
              <span
                className={`${
                  pathname === "/add-project" ? "text-[#625FFF]" : ""
                }`}
              >
                Add Projects
              </span>
            </Link>
            <Link
              to="/add-department"
              className={`flex items-center border-l-4 $ border-[#625FFF] rounded-sm pl-4 ${
                pathname === "/add-department"
                  ? "border-[#625FFF]"
                  : "border-white"
              }`}
            >
              <RiTeamFill
                className={`mr-2 h-5 w-5 ${
                  pathname === "/add-department"
                    ? "text-[#625FFF]"
                    : "border-white"
                }`}
              />{" "}
              <span
                className={`${
                  pathname === "/add-department" ? "text-[#625FFF]" : ""
                }`}
              >
                Add Department
              </span>
            </Link>
            <Link
              to="/employees"
              className={`flex items-center border-l-4 $ border-[#625FFF] rounded-sm pl-4 ${
                pathname === "/employees" ? "border-[#625FFF]" : "border-white"
              }`}
            >
              <RiTeamFill
                className={`mr-2 h-5 w-5 ${
                  pathname === "/employees" ? "text-[#625FFF]" : "border-white"
                }`}
              />{" "}
              <span
                className={`${
                  pathname === "/employees" ? "text-[#625FFF]" : ""
                }`}
              >
                Employee
              </span>
            </Link>
            <Link
              to="/accounts"
              className={`flex items-center border-l-4 $ border-[#625FFF] rounded-sm pl-4 ${
                pathname === "/accounts" ? "border-[#625FFF]" : "border-white"
              }`}
            >
              <RiMessage3Fill
                className={`mr-2 h-5 w-5 ${
                  pathname === "/accounts" ? "text-[#625FFF]" : "border-white"
                }`}
              />{" "}
              <span
                className={`${
                  pathname === "/accounts" ? "text-[#625FFF]" : ""
                }`}
              >
                Accounts
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
