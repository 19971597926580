import { IoMdNotifications } from "react-icons/io";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    navigate("/login");
  };
  return (
    <div className=" w-full flex py-2 justify-end items-center gap-x-2 px-2 md:px-4 bg-transparent">
      <IoMdNotifications className=" md:h-6 md:w-6 h-4 w-4 text-[#625FFF]" />
      <FaSignOutAlt
        className="md:h-6 md:w-6 h-4 w-4 text-[#625FFF]"
        onClick={handleLogout}
      />
    </div>
  );
};

export default Header;
