// import React, { useState,useEffect } from "react";
// import link from "../../assets/link.png"
// import departImage from "../../assets/department.png"
// import { MdEdit } from "react-icons/md";

// const AddDepartment = () => {
//   const [departments,setDepartments]=useState([{id: 1,title:'',image:null}])
//   const [submittedDepartments, setSubmittedDepartments] = useState([]);

//   const handleInputChange=(index,e)=>{
//     const {name,value}=e.target;
//     const updatedDepartments = [...departments];
//     updatedDepartments[index][name] = value;
//     setDepartments(updatedDepartments);

//   }
//   const handleImageChange = (index, event) => {
//     const { files } = event.target;
//     const updatedDepartments = [...departments];
//     updatedDepartments[index].image = files[0];
//     setDepartments(updatedDepartments);
//   };

//   const handleAddDepartment = () => {
//     setDepartments([...departments, { id: departments.length + 1, title: "", image: null }]);
//   };

//   const handleSubmit=()=>{
//     setSubmittedDepartments(departments);
//   }

//   useEffect(() => {
//     console.log(submittedDepartments);
//   }, [submittedDepartments]);

//   const [allDepartmentData,setAllDepartmentData]=useState(
//     [
//       {
//         id:1,
//         image:departImage,
//         title:"iot"
//       },
//       {
//         id:2,
//         image:departImage,
//         title:"iot"
//       },
//       {
//         id:3,
//         image:departImage,
//         title:"iot"
//       },
//       {
//         id:4,
//         image:departImage,
//         title:"iot"
//       }
//     ]
//   )

//   // const [editData,setEditData]=useState(allDepartmentData);

//   const [editData,setEditData]=useState([])

//   const imageHandle=(ind,e)=>{
//     const dept=[...allDepartmentData];
//     dept[ind].image=e.target.files[0]

//     // setAllDepartmentData(dept)
//     setEditData(dept)
//     console.log(allDepartmentData);
//   }

//   useEffect(()=>{
//     const dept=allDepartmentData;
//     setAllDepartmentData(dept)
//   },[allDepartmentData])

//   const [editMode, setEditMode] = useState(false);
//   const [editedTitle, setEditedTitle] = useState("");
//   const [editIndex, setEditIndex] = useState(null);
//   const toggleEditMode = (index) => {
//     setEditMode(true);
//     setEditIndex(index);
//     setEditedTitle(allDepartmentData[index].title);
//   };

//   const handleTitleEdit = () => {
//     const updatedData = [...allDepartmentData];
//     updatedData[editIndex].title = editedTitle;
//     // setAllDepartmentData(updatedData);
//     setEditData(updatedData)
//     setEditMode(false);
//   };

//   const handleEdit=()=>{
//     setAllDepartmentData([...editData])
//     console.log(allDepartmentData);
//   }
//   return (
//     <div className="flex p-1 gap-4">
//       <div className="w-[50%] h-[55vh] bg-white rounded-2xl p-4 px-4 mt-1 overflow-y-auto" style={{scrollbarWidth:"none"}}>
//         <h1 className="mb-3 text-[#625FFF] font-semibold">Add Department</h1>

//         {departments.map((department, index) => (
//           <div key={index}>
//             <div className="flex justify-between items-center mb-2">
//               <label className="w-[30%]">Department Title:</label>
//               <input
//                 name="title"
//                 value={department.title}
//                 onChange={(event) => handleInputChange(index, event)}
//                 placeholder="Enter Department Title"
//                 className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
//               />
//             </div>

//             <div className="w-full flex flex-col gap-2 mb-2">
//               <div className="flex justify-between items-center">
//                 <label className="w-[30%]">Add Image :</label>
//                 <input
//                   type="file"
//                   id={`upload-${index}`}
//                   // onChange={(event) => handleImageChange(index, event)}
//                   className="w-[70%] hidden"
//                   onChange={(event) => handleImageChange(index, event)}

//                   accept="image/*"
//                 />

//                 <label htmlFor={`upload-${index}`} className="w-[70%]">
//                     <input
//                       placeholder="Add Image"
//                       readOnly
//                       className="w-full p-1 border outline-none rounded-xl bg-[#f8f8f8]"
//                       value={department.image ? department.image.name : ""}
//                     />
//                 </label>
//               </div>
//               <div>
//                   <label htmlFor={`upload-${index}`} className="mt-4 w-full">
//                     <span className="text-white flex gap-1 w-[120px] float-right rounded-xl px-4 py-3 bg-[#625FFF]">
//                       <img src={link} className="w-[15px] h-[20px]" alt="Upload Icon"  />
//                       Upload
//                     </span>
//                   </label>
//                 </div>
//             </div>
//           </div>
//         ))}

//         <div className="w-full flex justify-center items-center mb-4">
//           <button className="border p-2" onClick={handleAddDepartment}>
//             Add Department
//           </button>
//         </div>

//           <div className="float-right ">
//             <button className="border mr-4 bg-white w-[120px] p-2 rounded-xl">Cancel</button>
//             <button className="border bg-[#625FFF] w-[120px] p-2 rounded-xl text-white" onClick={handleSubmit}>Done</button>
//           </div>
//       </div>

//       <div className="w-[50%] h-[90vh] bg-white rounded-2xl p-4 px-4 mt-1 overflow-y-auto ">
//           <h1 className="mb-3 text-[#625FFF] font-semibold">Edit Department</h1>

//           <div className="">
//             {
//               allDepartmentData.map((data,ind)=>{
//                 return(
//                   <div key={ind}>
//                     <div className="">
//                     {data.image && data.image instanceof Blob ? (
//                 <img src={URL.createObjectURL(data.image)} alt="Department" />
//               ) : (
//                 <img src={data.image} alt="Department" />
//               )}
//                     <input type="file" id={`img-${ind}`}  accept="image/*" className="hidden" onChange={(e)=>imageHandle(ind,e)}/>
//                     <label htmlFor={`img-${ind}`} className="relative -top-9 -left-2 float-right">
//                       <div className="bg-white  border rounded-full w-[30px] h-[30px] p-1" >
//                         <MdEdit color="blue" size={20} className=""/>
//                       </div>
//                     </label>
//                     </div>
//                     {/* <h1 className="flex gap-2 items-center">Department Title : {data.title} <MdEdit color="blue" /></h1> */}

//                     {editMode && editIndex === ind ? (
//                   <div className="flex items-center">
//                     <input
//                       type="text"
//                       value={editedTitle}
//                       onChange={(e) => setEditedTitle(e.target.value)}
//                     />
//                     <MdEdit color="blue" onClick={handleTitleEdit} />
//                   </div>
//                 ) : (
//                   <div className="flex">
//                   <span>Department Title :  </span><h1 className="flex gap-2 items-center">{" "}{data.title} <MdEdit color="blue" onClick={() => toggleEditMode(ind)} /></h1>

//                   </div>
//                 )}

//                   </div>
//                 )
//               })
//             }

//             <div className="float-right">
//               <button className="p-2 w-[70px] rounded-md mr-2 mb-4">Cancel</button>
//               <button className="bg-[#625FFF] p-2 text-white rounded-md w-[70px] mb-4" onClick={handleEdit}>Done</button>
//             </div>
//           </div>
//       </div>
//     </div>
//   )
// };

// export default AddDepartment;

// *********** New ***********

import React, { useState, useEffect } from "react";
import link from "../../assets/link.png";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from "axios";
import { toast } from "react-toastify";

const AddDepartment = () => {
  const [editDepartmentId, setEditDepartmentId] = useState(null);
  const [department, setDepartment] = useState({ name: "", image: null });
  const [departments, setDepartments] = useState([]);

  const [editChange, setEditChange] = useState({
    name: "",
    image: null,
    tempImg: null,
  });

  const getDepartments = async () => {
    try {
      const response = await axios.get(
        ` ${process.env.REACT_APP_API_URL}/admin/department`
      );
      if (response.status === 200) {
        setDepartments(response?.data?.departments);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/department/create-department`,
        department,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 201) {
        toast.success("Department Added");
        setDepartment(() => ({ name: "", image: null }));
        getDepartments();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const handleEdit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/department/edit-department/${editDepartmentId}`,
        editChange,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Department updated.");
        getDepartments();
        setEditDepartmentId(null);
        setEditChange({ name: "", image: null, tempImg: null });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDelete = async (deleteDepartmentId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/department/delete-department/${deleteDepartmentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        getDepartments();
        toast.success("Department deleted.");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <div className="flex p-1 gap-4">
      <div
        className="w-[50%] h-[55vh] bg-white rounded-2xl p-4 px-4 mt-1 overflow-y-auto"
        style={{ scrollbarWidth: "none" }}
      >
        <h1 className="mb-3 text-[#625FFF] font-semibold">Add Department</h1>
        <div className="flex justify-between items-center mb-2">
          <label className="w-[30%]">Department Title:</label>
          <input
            onChange={(e) =>
              setDepartment((prevData) => ({
                ...prevData,
                name: e.target.value,
              }))
            }
            placeholder="Enter Department Title"
            className="w-[70%] p-1 border outline-none rounded-xl bg-[#f8f8f8]"
          />
        </div>

        <div className="w-full flex flex-col gap-2 mb-2">
          <div className="flex justify-between items-center">
            <label className="w-[30%]">Add Image :</label>
            <input
              type="file"
              id="upload"
              className="w-[70%] hidden"
              onChange={(e) =>
                setDepartment((prevData) => ({
                  ...prevData,
                  image: e.target.files[0],
                }))
              }
              accept="image/*"
            />

            <label className="w-[70%]">
              <input
                placeholder="Add Image"
                readOnly
                className="w-full p-1 border outline-none rounded-xl bg-[#f8f8f8]"
                value={department?.image?.name}
              />
            </label>
          </div>
          <div>
            <label htmlFor="upload" className="mt-4 w-full">
              <span className="text-white flex gap-1 w-[120px] float-right rounded-xl px-4 py-3 bg-[#625FFF]">
                <img
                  src={link}
                  className="w-[15px] h-[20px]"
                  alt="Upload Icon"
                />
                Upload
              </span>
            </label>
          </div>
        </div>
        <div className="float-right ">
          <button className="border mr-4 bg-white w-[120px] p-2 rounded-xl">
            Cancel
          </button>
          <button
            className="border bg-[#625FFF] w-[120px] p-2 rounded-xl text-white"
            onClick={handleSubmit}
          >
            Done
          </button>
        </div>
      </div>

      <div
        className="w-[50%] h-[90vh] bg-white rounded-2xl p-4 px-4 mt-1 overflow-y-auto"
        style={{ scrollbarWidth: "none" }}
      >
        <h1 className="mb-3 text-[#625FFF] font-semibold">Edit Department</h1>

        <div className="">
          {departments?.map((department) => {
            return (
              <div key={department?.id}>
                <div className="">
                  {editDepartmentId && editDepartmentId === department.id ? (
                    <img
                      src={
                        editChange?.tempImg ||
                        `data:${department?.imageContentType};base64,${department?.imageData}`
                      }
                      alt="Department"
                    />
                  ) : (
                    <img
                      src={`data:${department?.imageContentType};base64,${department?.imageData}`}
                      alt="Departments"
                    />
                  )}

                  <label className="relative -top-9 -left-2 float-right flex gap-x-2">
                    <button
                      className="bg-white  border rounded-full w-[30px] h-[30px] flex justify-center items-center"
                      onClick={() => {
                        if (!editDepartmentId) {
                          setEditDepartmentId(department.id);
                        } else {
                        }
                      }}
                    >
                      <label htmlFor="image">
                        <MdEdit
                          color="blue"
                          size={20}
                          className="h-4 w-4 text-[#625FFF]"
                        />
                        {editDepartmentId && (
                          <input
                            id="image"
                            type="file"
                            className="hidden"
                            onChange={(e) =>
                              setEditChange((prevData) => ({
                                ...prevData,
                                image: e.target.files[0],
                                tempImg: URL.createObjectURL(e.target.files[0]),
                              }))
                            }
                          />
                        )}
                      </label>
                    </button>
                    <button
                      className="flex justify-center items-center text-[#625FFF] bg-white rounded-full h-8 w-8"
                      onClick={() => {
                        handleDelete(department.id);
                      }}
                    >
                      <RiDeleteBin6Fill className="h-4 w-4 " />
                    </button>
                  </label>
                </div>

                <div className="flex">
                  <span>Department Title : </span>
                  {editDepartmentId === department.id ? (
                    <input
                      value={editChange?.name}
                      onChange={(e) =>
                        setEditChange((prevData) => ({
                          ...prevData,
                          name: e.target.value,
                        }))
                      }
                      className="outline-none"
                      name="name"
                    />
                  ) : (
                    <h1 className="flex gap-2 items-center">
                      {department?.name}
                    </h1>
                  )}
                </div>
                {editDepartmentId && editDepartmentId === department.id && (
                  <div className="float-right">
                    <button
                      className="p-2 w-[70px] rounded-md mr-2 mb-4"
                      onClick={() => setEditDepartmentId(null)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-[#625FFF] p-2 text-white rounded-md w-[70px] mb-4"
                      onClick={handleEdit}
                    >
                      Done
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AddDepartment;
