import React, { useEffect, useState } from "react";
import link from "../../assets/link.png";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";

const EditTask = ({
  editTaskId,
  setEditTaskId,
  getProjectTasksByProjectId,
}) => {
  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (searchQuery) {
      const filtered = employees.filter((emp) =>
        emp.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees([]);
    }
  }, [searchQuery]);

  // Handle selection of an employee
  const handleSelectEmployee = (employee) => {
    if (!selectedEmployees.includes(employee)) {
      setSelectedEmployees([...selectedEmployees, employee]);
      setSearchQuery("");
    }
  };

  // Handle removal of an employee from selected list
  const handleRemoveEmployee = (employeeToRemove) => {
    const updatedEmployees = selectedEmployees.filter(
      (emp) => emp.id !== employeeToRemove
    );
    setSelectedEmployees(updatedEmployees);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      requirementsPdf: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.employees = selectedEmployees;
    if (!formData.requirementsPdf) {
      formData["requirementsPdf"] = null;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/lead/task/edit-task/${formData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        getProjectTasksByProjectId();
        toast.success("Task edited.");
        setEditTaskId(null);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const getTask = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lead/task/task/${editTaskId}`
      );
      if (response.status === 200) {
        const data = response.data.task;
        delete data["requirementsPdf"];
        setFormData(data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const getProjectEmployees = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/project-employees?projectId=${id}`
      );
      if (response.status === 200) {
        setEmployees(response.data.employees);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getTask();
    getProjectEmployees();
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-[500px] h-[500px] overflow-y-auto">
        <h1 className="font-semibold mb-4">Edit Task</h1>
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col gap-2">
            <label className="">Task Title</label>
            <input
              placeholder="title..."
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <label className="">Task No</label>
            <input
              type="number"
              placeholder="taskNo..."
              onChange={handleChange}
              name="number"
              value={formData.number}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <label className="">Description</label>
            <input
              placeholder="description..."
              onChange={handleChange}
              name="description"
              value={formData.description}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <label className="">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate?.slice(0, 10)}
              onChange={handleChange}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <label className="">Start Date</label>
            <input
              type="date"
              name="endDate"
              value={formData.endDate?.slice(0, 10)}
              onChange={handleChange}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <label className="w-[40%]">Assigned Employee:</label>
            <div className="w-[60%]">
              <input
                type="search"
                placeholder="Search Employee"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
              />

              {searchQuery && (
                <div className="flex flex-col mt-4 h-[100px] overflow-y-auto">
                  {filteredEmployees.map((employee) => (
                    <div
                      key={employee.id}
                      className="cursor-pointer py-2"
                      onClick={() => handleSelectEmployee(employee)}
                    >
                      {employee.name}
                    </div>
                  ))}
                </div>
              )}
              <div className="mt-4">
                {selectedEmployees.map((employee) => (
                  <div
                    key={employee.id}
                    className="flex items-center justify-between bg-gray-200 p-2 rounded-md mt-2"
                  >
                    <span>{employee.name}</span>
                    <button onClick={() => handleRemoveEmployee(employee.id)}>
                      &#9747;
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full flex mt-2 flex-col gap-2">
            <label className="">Requirements:</label>
            <textarea
              name="note"
              value={formData.note}
              onChange={handleChange}
              placeholder="Write Notes Here"
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>
          <div className="float-right mt-2 mb-4">
            <label htmlFor="upload" className="cursor-pointer">
              <span className="text-white flex gap-1 rounded-xl px-4 py-2 bg-[#625FFF] ">
                <img src={link} className="w-[15px] h-[20px]" />
                Attach Pdf
              </span>
            </label>
            <input type="file" id="upload" onChange={handleFileChange} hidden />
            {formData.pdf && <p>{formData.pdf.name}</p>}
          </div>
          <div className="w-full flex mt-2 flex-col gap-2">
            <label className="">File URL:</label>
            <input
              placeholder="Enter File URL Here"
              name="url"
              value={formData.url}
              onChange={handleChange}
              className="bg-[#f1f1f1] p-2 rounded-md w-full mb-4"
            />
          </div>
          <div className="float-right py-2">
            <button
              className="border mr-2 w-[120px] p-2"
              onClick={() => setEditTaskId(null)}
            >
              Go Back
            </button>
            <button type="submit" className="text-white bg-[#625FFF] p-2">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTask;
