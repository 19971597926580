import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { Header, Sidebar, ViewProject } from "./components/admin/index";
import {
  Accounts,
  AddDepartment,
  AddProject,
  Dashboard,
  Employee,
  LoginPage,
} from "./pages/admin/index";
import TeamLeadSidebar from "./components/TeamLead/TeamLeadSidebar";
import LeadDashboard from "./pages/teamLead/LeadDashboard";
import ViewTask from "./components/TeamLead/ViewTask";
import CreateTask from "./pages/teamLead/CreateTask";
import Team from "./pages/teamLead/Team";
import EmployeeSidebar from "./components/employee/EmployeeSidebar";
import EmployeeDashboard from "./pages/employee/EmployeeDashboard";
import ViewProjects from "./components/employee/ViewProjects";
import EmployeeTask from "./pages/employee/EmployeeTask";
import { useEffect } from "react";

export default function App() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role"); // Corrected this line

  useEffect(() => {
    if (!token || !role) {
      navigate("/login");
    }
  }, [token, role, navigate]);

  if (!token) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      {role === "ADMIN" && <Route path="/*" element={<Admin />} />}
      {role === "TEAMLEAD" && <Route path="/*" element={<TeamLead />} />}
      {role === "TEAMMEMBER" && (
        <Route path="/*" element={<EmployeeRoutes />} />
      )}
    </Routes>
  );
}

const Admin = () => {
  return (
    <div className="w-full h-screen flex relative bg-[#F8F8F8] app">
      <Sidebar />
      <div className="absolute top-0 right-0 h-screen w-[78%] bg-transparent">
        <Header />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/view-project/:id" element={<ViewProject />} />
          <Route path="/add-project" element={<AddProject />} />
          <Route path="/add-department" element={<AddDepartment />} />
          <Route path="/employees" element={<Employee />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/view-employee/:id" />
        </Routes>
      </div>
    </div>
  );
};

const TeamLead = () => {
  return (
    <div className="w-full h-screen flex relative bg-[#F8F8F8] app">
      <TeamLeadSidebar />
      <div className="absolute top-0 right-0 h-screen w-[78%] bg-transparent">
        <Header />
        <Routes>
          <Route path="/" element={<LeadDashboard />} />
          <Route path="/create-task" element={<CreateTask />} />
          <Route path="/view-task/:id" element={<ViewTask />} />
          <Route path="/team" element={<Team />} />
        </Routes>
      </div>
    </div>
  );
};

const EmployeeRoutes = () => {
  return (
    <div className="w-full h-screen flex relative bg-[#F8F8F8] app">
      <EmployeeSidebar />
      <div className="absolute top-0 right-0 h-screen w-[78%] bg-transparent">
        <Header />
        <Routes>
          <Route path="/" element={<EmployeeDashboard />} />
          <Route path="/view-projects/:id" element={<ViewProjects />} />
          <Route path="/employee-task" element={<EmployeeTask />} />
        </Routes>
      </div>
    </div>
  );
};
