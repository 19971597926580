import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import RequirementModal from "../../../modal/teamLead/RequirementModal";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import EditTask from "../editTask";

const TaskList = ({ selectedStatus, searchTerm }) => {
  const { id } = useParams();
  const [projectTasks, setProjectTasks] = useState([]);
  const [showRequirementsModal, setshowRequirementsModal] = useState(false);
  const [editTaskId, setEditTaskId] = useState(null);

  const [requirements, setRequirements] = useState({
    requirementsPdf: null,
    url: "",
    note: "",
  });

  const getProjectTasksByProjectId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lead/task/${id}?searchTerm=${searchTerm}&status=${selectedStatus}`
      );
      if (response.status === 200) {
        setProjectTasks(response?.data?.tasks);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const getStatus = (endDate, completedDate) => {
    const now = new Date(); // Current date and time

    // Convert endDate and completedDate to Date objects if they are not null
    const end = endDate ? new Date(endDate) : null;
    const completed = completedDate ? new Date(completedDate) : null;

    if (completed) {
      if (completed < end) {
        return "Completed";
      } else {
        return "Delayed";
      }
    } else {
      if (end && end > now) {
        return "In Progress";
      } else {
        return "Delayed";
      }
    }
  };

  useEffect(() => {
    getProjectTasksByProjectId();
  }, [searchTerm, selectedStatus]);

  return (
    <>
      {editTaskId && (
        <EditTask
          editTaskId={editTaskId}
          setEditTaskId={setEditTaskId}
          getProjectTasksByProjectId={getProjectTasksByProjectId}
        />
      )}
      {showRequirementsModal && (
        <RequirementModal
          setshowRequirementsModal={setshowRequirementsModal}
          requirements={requirements}
        />
      )}

      <div className="flex items-center justify-between  bg-[#DEDEF4] rounded-t-md py-4 mt-2">
        <div className="w-[80%] text-center">
          <span className="text-[#625FFF]">Tasks List</span>
        </div>

        <div className="flex w-[20%] text-center">
          <span className="text-[#625FFF]">Edit Task</span>
        </div>
      </div>

      <div
        className="bg-[#F8F8F8] rounded-t-md text-center py-4 grid grid-cols-8 border-b border-[#D9D9D9]"
        style={{ gridTemplateColumns: "0.5fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr" }}
      >
        <div className="flex justify-center items-center">
          <span className="text-[14px] text-[#585353]">Task No</span>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-[14px] text-[#585353]">Title</span>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-[14px] text-[#585353]">Description</span>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-[14px] text-[#585353]">Allocated Date</span>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-[14px] text-[#585353]">Deadline</span>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-[14px] text-[#585353]">Team Member</span>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-[14px] text-[#585353]">Requirements</span>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-[14px] text-[#585353]">Status</span>
        </div>
      </div>
      <div className="bg-[#F8F8F8] rounded-t-md ">
        {projectTasks?.map((task, index) => {
          return (
            <>
              <div
                className="grid grid-cols-8 mb-2"
                style={{
                  gridTemplateColumns: "0.5fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353] font-semibold">
                    {index + 1}
                  </span>
                </div>
                <div
                  className="flex justify-center items-center"
                  onClick={() => setEditTaskId(task.id)}
                >
                  <span className="text-[14px] text-[#585353] font-semibold">
                    {task.name}
                  </span>
                </div>
                <div
                  className="flex justify-center items-center "
                  onClick={() => setEditTaskId(task.id)}
                >
                  <span className="text-[14px] text-[#585353] font-semibold">
                    {task.description}
                  </span>
                </div>
                <div
                  className="flex justify-center items-center"
                  onClick={() => setEditTaskId(task.id)}
                >
                  <span className="text-[14px] text-[#585353] font-semibold">
                    {task?.startDate?.slice(0, 10)}
                  </span>
                </div>
                <div
                  className="flex justify-center items-center"
                  onClick={() => setEditTaskId(task.id)}
                >
                  <span className="text-[14px] text-[#585353] font-semibold">
                    {task?.endDate?.slice(0, 10)}
                  </span>
                </div>
                <div
                  className="flex justify-center items-center"
                  onClick={() => setEditTaskId(task.id)}
                >
                  {task?.employees.map((employee) => (
                    <span className="text-[14px] text-[#585353] font-semibold">
                      {employee?.name},
                    </span>
                  ))}
                </div>
                <div className="flex justify-center items-center">
                  <span
                    className="text-[14px] text-[#625FFF] underline font-semibold cursor-pointer"
                    onClick={() => {
                      setRequirements({
                        requirementsPdf: task.requirementsPdf,
                        url: task.url,
                        note: task.note,
                      });
                      setshowRequirementsModal(true);
                    }}
                  >
                    Edit Details
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[16px] text-[#585353] font-semibold">
                    {getStatus(task?.endDate, task?.completedDate) ===
                      "delayed" && (
                      <button className="bg-[#fccfe4] p-2 rounded-md text-[#f72585] w-[110px]">
                        Delayed
                      </button>
                    )}
                    {getStatus(task?.endDate, task?.completedDate) ===
                      "In Progress" && (
                      <button className="bg-[#dedef3] p-2 rounded-md text-[#6360ff] w-[110px]">
                        In Progress
                      </button>
                    )}
                    {getStatus(task?.endDate, task?.completedDate) ===
                      "Completed" && (
                      <button className="bg-[#cbffca] p-2 rounded-md text-[#3ffd3b] w-[110px]">
                        Completed
                      </button>
                    )}
                  </span>
                </div>
              </div>
              <hr />
            </>
          );
        })}
      </div>
    </>
  );
};

export default TaskList;
