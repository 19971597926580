import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import SearchComponent from "../TeamLead/SearchComponent";
import { toast } from "react-toastify";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import RequirementsModal from "../../modal/employee/RequirementsModal";

const ViewProjects = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [teamLead, setTeamLead] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [requirements, setRequirements] = useState({});
  const [showModal, setshowModal] = useState(false);

  const closeModal = () => setshowModal(false);

  const getTasksByProjectForEmployee = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/employee-tasks?projectId=${id}&employeeId=${localStorage.getItem(
          "userId"
        )}&searchTerm=${searchTerm}`
      );
      if (response.status === 200) {
        setTasks(response.data.tasks);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured");
    }
  };

  const getProjectEmployees = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/project-employees?projectId=${id}`
      );
      if (response.status === 200) {
        setEmployees(response.data.employees);
        setTeamLead(response.data.teamLead);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getTasksByProjectForEmployee();
    getProjectEmployees();
  }, [searchTerm]);

  useEffect(() => {
    getProjectEmployees();
  }, []);

  return (
    <>
      {showModal && (
        <RequirementsModal
          closeModal={closeModal}
          requirements={requirements}
        />
      )}
      <div className="mr-4 bg-white rounded-2xl py-4 px-2 relative top-4 h-[88vh]">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-semibold text-[#625FFF]">
            Ongoing Projects
          </h2>
          <div className="flex justify-end items-center gap-x-2">
            <div className="relative">
              <input
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="search project"
                className=" text-[14px] py-3 px-6 outline-none border rounded-md bg-white text-[#A9A9A9]"
              />
              <div className="absolute top-3 px-1 text-[#A9A9A9]">
                <CiSearch size={20} />
              </div>
            </div>

            <div className="text-[12px] py-3 px-2 outline-none bg-[#625FFF] text-white rounded-md">
              Department:
            </div>
          </div>
        </div>
        <div
          className="h-[55%] overflow-y-auto mb-4"
          style={{ scrollbarWidth: "none" }}
        >
          <h1 className="bg-[#DEDEF4] text-center p-3 rounded-tl-xl rounded-tr-xl text-[#625FFF]">
            Tasks Allocated
          </h1>
          <div
            className="bg-[#F8F8F8] rounded-t-md text-center py-4 grid grid-cols-8 border-b border-[#D9D9D9]"
            style={{ gridTemplateColumns: "0.5fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr" }}
          >
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Task No</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Title</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Description</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Allocated Date</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Deadline</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Team Member</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Details</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Status</span>
            </div>
          </div>
          {tasks.map((data, index) => (
            <>
              <div
                className="grid grid-cols-8 bg-[#F8F8F8] border-b border-[#D9D9D9] py-1"
                style={{
                  gridTemplateColumns: "0.5fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr",
                }}
              >
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">
                    {index + 1}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">
                    {data.name}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">
                    {data.description}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">
                    {data.startDate?.slice(0, 10)}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">
                    {data.endDate?.slice(0, 10)}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  {data.employees?.map((employee) => (
                    <span
                      key={employee.id}
                      className="text-[14px] text-[#585353]"
                    >
                      {employee.name}
                    </span>
                  ))}
                </div>
                <div className="flex justify-center items-center">
                  <span
                    className="text-[14px] text-[#625FFF] cursor-pointer underline"
                    onClick={() => {
                      setRequirements({
                        requirementsPdf: data.requirementsPdf,
                        note: data.note,
                        url: data.url,
                      });
                      setshowModal(true); 
                    }}
                  >
                    View Details
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">
                    {data.taskProgress === "Delayed" && (
                      <button className="bg-[#fccfe4] p-2 rounded-md text-[#f72585] w-[110px]">
                        {data.taskProgress}
                      </button>
                    )}
                    {data.taskProgress === "In Progress" && (
                      <button className="bg-[#dedef3] p-2 rounded-md text-[#6360ff] w-[110px]">
                        {data.taskProgress}
                      </button>
                    )}
                    {data.taskProgress === "Completed" && (
                      <button className="bg-[#cbffca] p-2 rounded-md text-[#3ffd3b] w-[110px]">
                        {data.taskProgress}
                      </button>
                    )}
                  </span>
                </div>
              </div>
            </>
          ))}
        </div>

        <div>
          <h1 className="bg-[#DEDEF4] text-center p-3 rounded-tl-xl rounded-tr-xl text-[#625FFF]">
            Project Team Members
          </h1>

          <div className="grid grid-cols-3 bg-[#F8F8F8] border-b py-2 border-[#D9D9D9]">
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Lead</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Employee 1</span>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">Employee 2</span>
            </div>
          </div>

          <div className="grid grid-cols-3 bg-[#F8F8F8] border-b py-2 rounded-bl-xl rounded-br-xl border-[#D9D9D9]">
            <div className="flex justify-center items-center">
              <span className="text-[14px] text-[#585353]">
                {teamLead.name}
              </span>
            </div>
            {employees.map((employee) => (
              <div
                className="flex justify-center items-center"
                key={employee.id}
              >
                <span className="text-[14px] text-[#585353]">
                  {employee.name}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div>
          <button
            onClick={() => navigate("/")}
            className="float-right bg-[#625FFF] p-2 w-[100px] text-white rounded-xl mt-2"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default ViewProjects;
