// import { departments } from "../../data.js";

// import { useState } from "react";
// import Calendar from "react-calendar";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import { useNavigate } from "react-router-dom";

// const responsive = {
//   xxxl: {
//     breakpoint: { max: 1700, min: 1500 },
//     items: 6,
//   },
//   xxl: {
//     breakpoint: { max: 1500, min: 1300 },
//     items: 5,
//   },
//   xl: {
//     breakpoint: { max: 1300, min: 1100 },
//     items: 5,
//   },
//   lg: {
//     breakpoint: { max: 1100, min: 900 },
//     items: 4,
//   },
//   md: {
//     breakpoint: { max: 900, min: 700 },
//     items: 3,
//   },
//   s: {
//     breakpoint: { max: 700, min: 500 },
//     items: 2,
//   },
//   xs: {
//     breakpoint: { max: 500, min: 400 },
//     items: 1,
//   },
// };

// const Dashboard = () => {
//   const navigate = useNavigate();
//   const [currentDepartment, setCurrentDepartment] = useState("IT - Full Stack");
//   const [searchTerm, setSearchTerm] = useState("");
//   const [clients, setClients] = useState(departments[0].clients);

//   return (
//     <div className="overflow-hidden md:pl-2 px-2 pt-4">
//       <div className="flex h-[34vh] justify-start items-end w-full gap-x-2">
//         <div className="bg-white rounded-2xl overflow-x-auto">
//           <h2 className="font-semibold md:text-2xl text-lg text-[#625FFF] mt-8">
//             Welcome Admin
//           </h2>
//           <p className="font-semibold text-[14px] md:text[18px] mt-5">
//             Departmensts
//           </p>
//           <Carousel
//             responsive={responsive}
//             swipeable={true}
//             arrows={false}
//             className="mt-2"
//           >
//             {departments.map((department, index) => (
//               <div
//                 key={department.id}
//                 className="w-28 h-28 md:w-32 rounded-tl-xl flex flex-col items-center cursor-pointer"
//                 onClick={() => {
//                   setCurrentDepartment(department.name);
//                   setClients(departments[index].clients);
//                 }}
//               >
//                 <div
//                   className="h-full w-full bg-cover bg-center rounded-tl-xl"
//                   style={{ backgroundImage: `url(${department.imgUrl})` }}
//                 ></div>
//                 <p className="text-center mt-1 text-[14px]">
//                   {department.name}
//                 </p>
//               </div>
//             ))}
//           </Carousel>
//         </div>
//         <Calendar className="md:text-[12px] text-[10px] leading-tight border-none md:h-60 md:w-60 h-44 w-auto mr-6" />
//       </div>
//       <div className="h-[56vh] mt-6 mr-4">
//         <div className="flex justify-between items-center">
//           <h2 className="text-xl font-semibold text-[#625FFF]">
//             Ongoing Projects
//           </h2>
//           <div className="flex justify-end items-center gap-x-2">
//             <input
//               onChange={(e) => setSearchTerm(e.target.value)}
//               placeholder="search project"
//               className=" text-[12px] py-4 px-2 outline-none bg-white text-[#A9A9A9]"
//             />
//             <div className="text-[12px] py-4 px-2 outline-none bg-white text-[#A9A9A9]">
//               Department: {currentDepartment}
//             </div>
//           </div>
//         </div>
//         <div>
//           <div className="flex justify-between items-center py-4 px-4 bg-[#DEDEF4] rounded-t-md">
//             <span className="text-[16px] text-[#625FFF] font-semibold">
//               Client
//             </span>
//             <span className="text-[16px] text-[#625FFF] font-semibold">
//               Handled By
//             </span>
//             <span className="text-[16px] text-[#625FFF] font-semibold">
//               Project
//             </span>
//           </div>

//           <div
//             className=" h-[48vh] overflow-y-auto"
//             style={{ scrollbarWidth: "none" }}
//           >
//             {clients.map((client, index) => (
//               <div
//                 className="flex justify-between items-center py-4 px-4 rounded-t-md cursor-pointer"
//                 onClick={() => navigate("/view-project/woienwe")}
//               >
//                 <span className="text-[16px] font-semibold">
//                   {index + 1}. {client.firstName}
//                 </span>
//                 <span className="text-[16px] font-semibold">
//                   {client.handledBy}
//                 </span>
//                 <span className="text-[16px] font-semibold">
//                   {client.status}
//                 </span>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

// *************** New ******************

import axios from "axios";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const responsive = {
  xxxl: {
    breakpoint: { max: 1900, min: 1700 },
    items: 6,
  },
  xxxl: {
    breakpoint: { max: 1700, min: 1500 },
    items: 6,
  },
  xxl: {
    breakpoint: { max: 1500, min: 1300 },
    items: 5,
  },
  xl: {
    breakpoint: { max: 1300, min: 1100 },
    items: 5,
  },
  lg: {
    breakpoint: { max: 1100, min: 900 },
    items: 4,
  },
  md: {
    breakpoint: { max: 900, min: 700 },
    items: 3,
  },
  s: {
    breakpoint: { max: 700, min: 500 },
    items: 2,
  },
  xs: {
    breakpoint: { max: 500, min: 400 },
    items: 1,
  },
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);

  const getProjects = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/project`,
        {
          department: currentDepartment,
          searchTerm,
        }
      );

      if (response.status === 200) {
        setProjects(response.data.projects);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured");
    }
  };

  const getDepartments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/department`
      );
      if (response.status === 200) {
        setDepartments(response?.data.departments);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occured");
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    getProjects();
  }, [searchTerm, currentDepartment]);

  return (
    <div className="overflow-hidden md:pl-2 px-2 pt-4">
      <div className="flex h-[34vh] justify-start items-end w-full gap-x-2">
        <div
          className="bg-white rounded-2xl overflow-x-auto relative" // Add relative positioning
          style={{ width: `calc(100% - 240px)` }}
        >
          <h2 className="font-semibold md:text-2xl text-lg text-[#625FFF] mt-8">
            Welcome Admin
          </h2>
          <p className="font-semibold text-[14px] md:text[18px] mt-5">
            Departmensts
          </p>
          <Carousel
            responsive={responsive}
            swipeable={true}
            arrows={false}
            className="mt-2"
          >
            {departments?.map((department) => (
              <div
                key={department.id}
                className="w-28 h-28 md:w-32 rounded-tl-xl flex flex-col items-center cursor-pointer"
                onClick={() => {
                  setCurrentDepartment(department.name);
                }}
              >
                {department.name !== currentDepartment && ( // Conditionally render the div
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-white opacity-40 rounded-tl-xl"
                    style={{ zIndex: 1 }} // Ensure the div is above the card
                  ></div>
                )}
                <div
                  className="h-full w-full bg-cover bg-center rounded-tl-xl"
                  style={{
                    backgroundImage: `url(data:${department?.imageContentType};base64,${department?.imageData})`,
                  }}
                ></div>
                <p className="text-center mt-1 text-[14px]">
                  {department?.name}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
        <Calendar className="md:text-[12px] text-[10px] leading-tight border-none md:h-60 md:w-60 h-44 w-auto mr-6" />
      </div>

      <div className="h-[50vh] mt-6 mr-4">
        <div className="flex justify-between items-center">
          <h2 className="text-[14px] md:text-[18px] font-semibold text-[#625FFF]">
            Ongoing Projects
          </h2>
          <div className="flex justify-end items-center gap-x-2">
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="search project"
              className=" text-[12px] py-3 px-2 outline-none bg-white text-[#A9A9A9]"
            />
            <div className="text-[12px] py-3 px-2 outline-none bg-white text-[#A9A9A9]">
              Department: {currentDepartment}
            </div>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-3 py-3 px-4 bg-[#DEDEF4] rounded-t-md">
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Client
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Handled By
            </span>
            <span className="text-[16px] text-[#625FFF] font-semibold">
              Project
            </span>
          </div>

          <div
            className=" h-[48vh] overflow-y-auto pb-6"
            style={{ scrollbarWidth: "none" }}
          >
            {projects.map((project, index) => (
              <div
                className="grid grid-cols-3 py-3 px-4 rounded-t-md cursor-pointer"
                onClick={() => navigate(`/view-project/${project.id}`)}
              >
                <span className="text-[16px] font-semibold">
                  {index + 1}. {project.clientName}
                </span>
                <span className="text-[16px] font-semibold">
                  {project.teamLead?.name}
                </span>
                <span className="text-[16px] font-semibold">
                  {project.projectName}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
