import React, { useState } from "react";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const RequirementsModal = ({
  projectName,
  requirements,
  requirementsPdfData,
  setShowModal,
  getProject,
}) => {
  const { id } = useParams();

  const [isEditing, setIsEditing] = useState(false);
  const [editedRequirements, setEditedRequirements] = useState(requirements);
  const [editedPdf, setEditedPdf] = useState(null);

  const openPDFInNewTab = () => {
    const blob = new Blob([new Uint8Array(requirementsPdfData?.data)], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handleSaveClick = async () => {
    try {
      if (isEditing) {
        const formData = new FormData();
        formData.append("requirements", editedRequirements);
        if (editedPdf) {
          formData.append("requirementsPdf", editedPdf);
        }
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/admin/project/edit-project/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          setIsEditing(false);
          setShowModal(false);
          getProject();
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
      // Handle error
    }
  };

  return (
    <div className="fixed h-screen w-full bg-black/30 flex justify-center items-center top-0 left-0 z-10">
      <div className="bg-white rounded-2xl w-[65%] h-[70%] flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center w-[80%] mb-4">
          <h2 className="text-xl font-semibold mt mb-10">
            Requirements: {projectName}
          </h2>
          <div className="flex flex-row w-full mb-4 max-h-[48%]">
            <div className="text-[14px] font-semibold w-[20%]">Note:</div>
            <div
              className="bg-[#F8F8F8] px-4 rounded-md w-[80%] flex justify-start items-center overflow-y-auto"
              style={{ scrollbarWidth: "thin" }}
            >
              {isEditing ? (
                <textarea
                  value={editedRequirements}
                  onChange={(e) => setEditedRequirements(e.target.value)}
                  className="w-full h-full bg-transparent outline-none"
                />
              ) : (
                <span>{requirements}</span>
              )}
            </div>
          </div>
          <div className="flex w-full">
            <span className="text-[14px] font-semibold w-[20%]">Pdf:</span>
            <div className="flex flex-row gap-x-2 justify-start items-center w-[80%]">
              {isEditing ? (
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => setEditedPdf(e.target.files[0])}
                />
              ) : (
                <>
                  <button
                    className="text-[#625FFF] font-semibold"
                    onClick={openPDFInNewTab}
                  >
                    View Pdf
                  </button>
                  <MdEdit
                    className="text-[#585353] h-4 w-4"
                    onClick={() => setIsEditing(true)}
                  />
                </>
              )}
              <RiDeleteBin6Fill className="text-[#585353] h-4 w-4" />
            </div>
          </div>
          <div className="flex justify-end items-center w-full">
            {isEditing ? (
              <>
                <button
                  className="text-[#625FFF] bg-white rounded-md px-8 py-2 mr-2"
                  onClick={() => {
                    setEditedRequirements(requirements);
                    setEditedPdf(null);
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="text-white bg-[#625FFF] rounded-md px-8 py-2"
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </>
            ) : (
              <button
                className="text-white bg-[#625FFF] rounded-md px-8 py-2"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequirementsModal;
