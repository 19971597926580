import axios from "axios";
import React from "react";
import { toast } from "react-toastify";

const TaskStatus = ({ task, status, getTasks }) => {
  const role = localStorage.getItem("role");
  const markTaskAsCompleted = async (taskId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employee/task-mark-as-completed`,
        null,
        {
          params: {
            taskId,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        getTasks();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };
  let bgColor;
  let btnColor;
  let btnText;
  switch (status) {
    case "Completed":
      bgColor = "#d9d8f9";
      btnColor = "#817ded";
      btnText = "Mark in Progress";
      break;
    case "delayed":
      bgColor = "#f8cee1";
      btnColor = "#f22184";
      btnText = "Mark as Complete";
      break;
    case "In Progress":
      bgColor = "#d3f9d2";
      btnColor = "#3ffd3b";
      btnText = "Mark as Complete";
      break;
    default:
      bgColor = "bg-gray-300";
  }
  return (
    <div
      className={`p-2 rounded-xl flex flex-col justify-between  h-[200px] mb-4 `}
      style={{ backgroundColor: bgColor }}
    >
      <div>
        <p>{task.number}</p>
        <h1>{task.name}</h1>
        <p>{task.description}</p>
      </div>

      <div>
        <button
          className="float-right border p-2 relative bottom-0 text-white rounded-xl"
          style={{ backgroundColor: btnColor }}
          onClick={() => {
            if (!task.completedDate && role === "TEAMMEMBER")
              markTaskAsCompleted(task.id);
          }}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default TaskStatus;
