import React, { useEffect, useState } from "react";
import Pie from "../../components/employee/Pie";
import { IoTrash } from "react-icons/io5";
import { SiTicktick } from "react-icons/si";
import { CiSearch } from "react-icons/ci";
import { BsArrowsAngleExpand } from "react-icons/bs";
import TaskStatus from "../../components/TeamLead/TaskStatus";
import TaskStatusModal from "../../modal/teamLead/TaskStatusModal";
import { toast } from "react-toastify";
import axios from "axios";
import RequirementModal from "../../modal/teamLead/RequirementModal";

const EmployeeTask = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [progressTasks, setProgressTasks] = useState([]);
  const [delayedTasks, setDelayedTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);
  const [searchTerm, setTaskSearchTerm] = useState("");
  const [prioritySearch, setPrioritySearch] = useState("");
  const [incompleteTasks, setIncompleteTasks] = useState([]);
  const [priorityTasks, setPriorityTasks] = useState([]);
  const [showRequirementsModal, setshowRequirementsModal] = useState(false);
  const [requirements, setRequirements] = useState({
    requirementsPdf: null,
    url: "",
    note: "",
  });

  const getProjects = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/employee-project/${localStorage.getItem("userId")}`
      );
      if (response.status === 200) {
        setProjects(response.data.projectIds);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const getTasks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/employee-tasks-by-project?projectId=${
          project?.id
        }&employeeId=${localStorage.getItem("userId")}&searchTerm=${searchTerm}`
      );
      if (response.status === 200) {
        setCompletedTasks(response.data.completed);
        setDelayedTasks(response.data.delayed);
        setProgressTasks(response.data.inProgress);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const getPriorityTasks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/employee/priority-tasks`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setPriorityTasks(response.data.priorityTasks);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const getIncompleteTasksByEmployeeId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/employee/incomplete-tasks?searchTerm=${prioritySearch}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setIncompleteTasks(response.data.incompleteTasks);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const addPriorityTask = async (taskId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employee/priority-task?taskId=${taskId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setPrioritySearch("");
        getPriorityTasks();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const deleteTask = async (taskId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/employee/delete-priority-task/${taskId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        getPriorityTasks();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  const markTaskAsCompleted = async (taskId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employee/task-mark-as-completed`,
        null,
        {
          params: {
            taskId,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        getPriorityTasks();
        getIncompleteTasksByEmployeeId();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "An error occured.");
    }
  };

  useEffect(() => {
    getProjects();
    getPriorityTasks();
  }, []);

  useEffect(() => {
    getIncompleteTasksByEmployeeId();
  }, [prioritySearch]);

  useEffect(() => {
    if (project?.id) getTasks();
  }, [searchTerm, project]);

  return (
    <>
      {showRequirementsModal && (
        <RequirementModal
          setshowRequirementsModal={setshowRequirementsModal}
          requirements={requirements}
        />
      )}
      <div
        className="mr-4 bg-white rounded-2xl py-4 px-2 relative top-4 h-[88vh] overflow-y-auto"
        style={{ scrollbarWidth: "none" }}
      >
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-semibold text-[#625FFF]">
            {project?.name}
          </h2>
          <div className="flex justify-end items-center gap-x-2">
            <div className="relative">
              <input
                onChange={(e) => setPrioritySearch(e.target.value)}
                placeholder="search project"
                className=" text-[14px] py-3 px-6 outline-none border rounded-md bg-white text-[#A9A9A9]"
              />
              <div className="absolute top-3 px-1 text-[#A9A9A9]">
                <CiSearch size={20} />
              </div>

              {prioritySearch.length > 0 && (
                <ul
                  className="bg-gray-100  p-2 rounded-md absolute z-10 w-full h-[200px] overflow-y-auto"
                  style={{ scrollbarWidth: "none" }}
                >
                  {incompleteTasks?.map((task) => (
                    <li
                      key={task.id}
                      className="cursor-pointer py-1 px-2 hover:bg-gray-200"
                      onClick={() => addPriorityTask(task.id)}
                    >
                      {task.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="text-[12px] py-3 px-2 outline-none bg-[#625FFF] text-white rounded-md">
              Department:
            </div>
          </div>
        </div>

        <div className=" flex gap-4 h-[45vh]">
          {/* ***** LEft ******* */}
          <Pie />

          {/* ***** Right ******* */}
          <div
            className="w-[70%] h-full rounded-md"
            style={{
              boxShadow:
                " rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            }}
          >
            <div
              className="overflow-y-auto h-full"
              style={{ scrollbarWidth: "none" }}
            >
              <h1 className="bg-[#dedef4] text-[#625fff] p-2 text-center rounded-tl-md rounded-tr-md">
                Tasks On Priority
              </h1>
              <div className="grid grid-cols-6 border-b border-[#D9D9D9] py-3">
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">Task No</span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">Project</span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">Title</span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">Deadline</span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">Details</span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[14px] text-[#585353]">Action</span>
                </div>
              </div>
              {priorityTasks.map((task, index) => (
                <div
                  key={task.id}
                  className="grid grid-cols-6 border-b border-[#D9D9D9] py-3 overflow-y-auto"
                >
                  <div className="flex justify-center items-center">
                    <span className="text-[14px] text-[#585353]">
                      {task.number}
                    </span>
                  </div>
                  <div className="flex justify-center items-center">
                    <span className="text-[14px] text-[#585353]">
                      {task.projectName}
                    </span>
                  </div>
                  <div className="flex justify-center items-center">
                    <span className="text-[14px] text-[#585353]">
                      {task.name}
                    </span>
                  </div>
                  <div className="flex justify-center items-center">
                    <span className="text-[14px] text-[#585353]">
                      {task?.endDate?.slice(0, 10)}
                    </span>
                  </div>
                  <div className="flex justify-center items-center">
                    <span
                      className="text-[14px] text-[#625fff] underline cursor-pointer"
                      onClick={() => {
                        setRequirements({
                          requirementsPdf: task.requirementsPdf,
                          url: task.url,
                          note: task.note,
                          projectName: task.projectName,
                        });
                        setshowRequirementsModal(true);
                      }}
                    >
                      view details
                    </span>
                  </div>
                  <div className="flex gap-2 justify-center items-center">
                    <IoTrash
                      color="red"
                      cursor="pointer"
                      onClick={() => deleteTask(task.id)}
                    />
                    <SiTicktick
                      color="#3ffd3b"
                      cursor="pointer"
                      onClick={() => markTaskAsCompleted(task.id)}
                    />
                  </div>
                  {/* Add more columns as needed */}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* ************** Bottom ********************* */}
        <div>
          <div className="flex justify-between items-center mt-2">
            <h2 className="text-[#625fff] font-semibold">{project?.name}</h2>
            <div className="flex justify-end items-center gap-x-2">
              <div className="relative">
                <input
                  onChange={(e) => setTaskSearchTerm(e.target.value)}
                  placeholder="search project"
                  className=" text-[14px] py-3 px-6 outline-none border rounded-md bg-white text-[#A9A9A9]"
                />
                <div className="absolute top-3 px-1 text-[#A9A9A9]">
                  <CiSearch size={20} />
                </div>
              </div>

              <select
                value={JSON.stringify(project)}
                className="text-[12px] py-3 px-2 w-[180px] outline-none bg-[#625FFF] text-white rounded-md"
                onChange={(e) => {
                  setProject(JSON.parse(e.target.value));
                }}
              >
                <option value="" disabled selected>Select</option>
                {projects.map((project) => (
                  <option key={project.id} value={JSON.stringify(project)}>
                    {project?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* ************  TAsk ************** */}
          <div
            className="h-auto  p-4 rounded-xl mt-2"
            style={{
              boxShadow:
                " rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            }}
          >
            <div className="grid grid-cols-3 gap-4">
              {progressTasks.length > 0 && (
                <div className="cursor-pointer">
                  <h2 className="text-xl text-center mb-2">
                    {`In Progress - ${progressTasks.length}/${
                      progressTasks.length +
                      delayedTasks.length +
                      completedTasks.length
                    }`}
                  </h2>
                  <TaskStatus task={progressTasks[0]} status="In Progress" />
                </div>
              )}
              {completedTasks.length > 0 && (
                <div className="cursor-pointer">
                  <h2 className="text-xl text-center mb-2">
                    {`Completed - ${completedTasks.length}/${
                      progressTasks.length +
                      delayedTasks.length +
                      completedTasks.length
                    }`}
                  </h2>
                  <TaskStatus task={completedTasks[0]} status="Completed" />
                </div>
              )}
              {delayedTasks.length > 0 && (
                <div className="cursor-pointer">
                  <h2 className="text-xl text-center mb-2">
                    {`Delayed - ${delayedTasks.length}/${
                      progressTasks.length +
                      delayedTasks.length +
                      completedTasks.length
                    }`}
                  </h2>
                  <TaskStatus task={delayedTasks[0]} status="delayed" />
                </div>
              )}
            </div>

            <div className="p-2 h-auto flex justify-end">
              <div
                onClick={() => setModalOpen(true)}
                className="cursor-pointer"
              >
                <BsArrowsAngleExpand />
                <p>zoom</p>
              </div>
            </div>
          </div>

          {modalOpen && (
            <TaskStatusModal
              setModalOpen={setModalOpen}
              progressTasks={progressTasks}
              delayedTasks={delayedTasks}
              completedTasks={completedTasks}
              getTasks={getTasks}
              projects={projects}
              setProject={setProject}
              setTaskSearchTerm={setTaskSearchTerm}
              project={project}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeTask;
