import React from "react";
import { Link } from "react-router-dom";

const RequirementsModal = ({ closeModal, requirements }) => {
  const openPDFInNewTab = () => {
    const blob = new Blob(
      [new Uint8Array(requirements.requirementsPdf?.data)],
      {
        type: "application/pdf",
      }
    );
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white rounded-md p-8 w-full md:w-1/2">
        <h2 className="text-center mb-4">
          Requirement : ${requirements.projectName}
        </h2>
        <div className="h-full">
          <div className="flex w-full mb-2 h-[20%]">
            <label className="w-[20%] p-4">Notes :</label>
            <div className="text-[#585353] bg-[#f8f8f8] w-[80%] p-4 rounded-xl">
              {requirements.note}
            </div>
          </div>

          <div className="flex w-full mb-2 h-[20%]">
            <label className="w-[20%] p-4">Pdf :</label>
            <button
              onClick={() => openPDFInNewTab()}
              className="text-[#6360FF] underline"
            >
              View PDF
            </button>
          </div>

          <div className="flex w-full mb-2 h-[20%]">
            <label className="w-[20%] p-4">URL :</label>
            <div className="w-[80%]">
              <Link to={`${requirements.url}`}>
                <p className="w-[full] text-[#6360FF] break-all cursor-pointer underline p-4">
                  {requirements.url}
                </p>
              </Link>
            </div>
          </div>
        </div>

        <div className="float-right mb-2">
          <button
            className="bg-[#6360FF] text-white p-2 w-[100px] rounded-xl"
            onClick={() => closeModal()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequirementsModal;
