import React from "react";
import TaskStatus from "../../components/TeamLead/TaskStatus";

const TaskStatusModal = ({
  setModalOpen,
  completedTasks,
  delayedTasks,
  progressTasks,
  getTasks,
  projectId,
  projects,
  setProject,
  setTaskSearchTerm,
  project,
}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 ">
      <div className="bg-white rounded-xl p-4 w-[80%] h-[85%] ">
        <div className="flex justify-between items-center mb-4 h-[10%]">
          <h2 className="text-2xl font-medium text-[#625FFF] ">
            {project?.name}
          </h2>
          <div className="flex gap-2">
            <div>
              <input
                onChange={(e) => setTaskSearchTerm(e.target.value)}
                type="search"
                placeholder="search here..."
                className="outline-none rounded-md border p-2"
              />
            </div>
            <div>
              <select
                value={JSON.stringify(project)}
                onChange={(e) => setProject(JSON.parse(e.target.value))}
                className="bg-[#625FFF] p-2 text-white rounded-md outline-none w-[150px]"
              >
                <option value="" disabled selected>Select</option>
                {projects.map((project) => (
                  <option key={project.id} value={JSON.stringify(project)}>
                    {project?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div
          className="grid grid-cols-3 gap-4 h-[80%]  overflow-y-auto"
          style={{ scrollbarWidth: "none" }}
        >
          <div>
            <h2 className="text-xl text-center mb-2">In Progress</h2>
            {progressTasks?.map((task) => (
              <TaskStatus
                task={task}
                status="In Progress"
                getTasks={getTasks}
                projectId={projectId}
              />
            ))}
          </div>
          <div>
            <h2 className="text-xl text-center mb-2">Completed</h2>
            {completedTasks?.map((task) => (
              <TaskStatus
                task={task}
                status="Completed"
                getTasks={getTasks}
                projectId={projectId}
              />
            ))}
          </div>
          <div>
            <h2 className="text-xl text-center mb-2">Delayed</h2>
            {delayedTasks?.map((task) => (
              <TaskStatus
                task={task}
                status="delayed"
                getTasks={getTasks}
                projectId={projectId}
              />
            ))}
          </div>
        </div>
        <div className="">
          <button
            className="border p-2 float-right bg-[#625FFF] w-[100px] text-white rounded-xl"
            onClick={() => setModalOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskStatusModal;
